import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Grid,
  Popover,
  Progress,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import Sticky from "react-stickynode";

import { Link, useHistory, useParams } from "react-router-dom";
import Text from "antd/es/typography/Text";
import HTMLReactParser from "html-react-parser";
import {
  BookOutlined,
  CaretRightOutlined,
  CommentOutlined,
  CopyOutlined,
  DollarCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  PaperClipOutlined,
  PauseOutlined,
  PhoneOutlined,
  ReadOutlined,
  RocketOutlined,
  StarOutlined,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import { UploadChangeParam } from "antd/lib/upload/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import TicketService from "../../../services/tickets-service";

import {
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import {
  clearCommentsState,
  clearTicketState,
  createComment,
  fetchComments,
  getTicket,
  updateTicket,
} from "../../../actions/tickets";
import AttachmentsList from "./attachments";
import Comments from "./comments";
import {
  formatMoney,
  formatSeconds,
  getMobileOperatingSystem,
  makeAvatarName,
  openNotificationWithIcon,
  placeCaretAtEnd,
  stringToColour,
  transformToFormikError,
} from "../../../utils/common";
import LastActivity from "./last-activity";
import AssignUsers from "./QuickForms/AssignUsers";
import TicketType from "../../Shared/TicketType";
import {
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";
import TicketTypeForm from "./QuickForms/TicketTypeForm";
import {
  addWorktime,
  endWorktime,
  getTicketWorktimesShort,
  getUserActiveWorktime,
  getUserLastWorktimes,
  startWorktime,
} from "../../../actions/worktimes";
import Can from "../../Shared/Can";
import EstimationPopover from "./QuickForms/EstimationPopover";
import PriorityDropdown from "./QuickForms/PriorityDropdown";
import DueDatePopover from "./QuickForms/DueDatePopover";
import CostPopover from "./QuickForms/CostPopover";
import ForwardUsers from "./QuickForms/ForwardUsers";
import ProgressPopover from "./QuickForms/ProgressPopover";
import { TicketStatus } from "../../../types/ticket";
import CloseTicket from "./QuickForms/CloseTicket";
import OpenTicket from "./QuickForms/OpenTicket";
import ValuationAnswer from "./QuickForms/ValuationAnswer";
import { getUsers } from "../../../actions/users";
import ToggleTimeButton from "../../Shared/ToggleTimeButton";
import RemoveRecord from "../../Shared/RemoveRecord";
import LeaveTicketButton from "../../Shared/LeaveTicketButton";
import { setLoggedUser } from "../../../actions/auth";

import useKeyPress from "../../../hooks/useKeyPress";
import { MetaTitle } from "../../Shared/MetaTitle";
import UsersService from "../../../services/users-service";
import NoContentError from "../../Shared/NoContentError";

interface IRouteParams {
  id: string;
  groupId: string;
  commentId: string;
  hiddenId?: string;
  eventId?: string;
}
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const TicketPage = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    id,
    groupId,
    hiddenId,
    commentId,
    eventId,
  } = useParams<IRouteParams>();
  const { useBreakpoint } = Grid;
  const history = useHistory();
  const screens = useBreakpoint();
  const [sticky, setSticky] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isCommentsExpanded, setCommentsExpanded] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [uploadList, setUploadList] = useState<any[]>([]);
  const [visibleAssignForm, setVisibleAssignForm] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [scratchpad, setScratchpad] = useState({ comment: null, hidden: null });
  const [isUnreadLoading, setUnreadLoading] = useState(false);
  const scrollRef = useRef<any>();
  const hiddenRef = useRef<any>();
  const {
    ticket,
    notifications,
    isRequest,
    worktime,
    worktimeRequest,
    comments,
    users,
    leaders,
    members,
    getUsersAction,
    setLoggedUserAction,
    getLeadersAction,
    getMembersAction,
    getTicketAction,
    getTicketTypesAction,
    startWorktimeAction,
    endWorktimeAction,
    getTicketWorktimesShortAction,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
    setCurrentEntityForbiddenAction,
    getTicketCommentsAction,
    createTicketCommentAction,
    clearTicketStateActon,
    clearCommentsStateAction,
    updateTicketAction,
    loggedUser,
    isUsersLoading,
    isCommentsRequest,
    isCreateCommentRequest,
    isLeadersLoading,
    isMembersLoading,
    activeWorktime,
    toggleRequest,
    getLastWorktimeAction,
    getActiveWorktimeAction,
    addWorktimeAction,
  } = props;

  const [ticketAttachments, setTicketAttachments] = useState([]);
  const [linkId, setLinkId] = useState<string | null>(null);
  const [isAttachmentsLoading, setAttachmentsLoading] = useState(false);
  const fetchAttachments = useCallback(() => {
    setAttachmentsLoading(true);
    TicketService.getTicketAttachments(id)
      .then((response) => {
        setTicketAttachments(response.data);
      })
      .finally(() => {
        setAttachmentsLoading(false);
      });
  }, [id]);
  const focusComment = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "u") {
      if (hiddenRef) {
        placeCaretAtEnd(hiddenRef?.current?.querySelector(".ql-editor"));
      }
    }
    if (event.key.toLowerCase() === "k") {
      if (scrollRef) {
        placeCaretAtEnd(scrollRef?.current?.querySelector(".ql-editor"));
      }
    }
  };
  useKeyPress("shift+ctrl", ["k", "u"], focusComment);

  const handleProgressChange = (file: any, value: number) => {
    setUploadList((list) => {
      const idx = list.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...list[idx] };

        copyItem.progress = value;

        return [...list.slice(0, idx), copyItem, ...list.slice(idx + 1)];
      }
      return [...list];
    });
  };

  const handleChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;

    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((list) => [item, ...list]);
  };

  const handleUpload = (options: any) => {
    // setIsUploading(true);
    return TicketService.uploadFile(
      id,
      Math.floor(Date.now() / 1000),
      options.file,
      (change) => handleProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((list) => {
          const idx = list.findIndex(
            (item: any) => item.id === options.file.uid
          );
          if (idx > -1) {
            return [
              ...list.slice(0, idx),
              response.data,
              ...list.slice(idx + 1),
            ];
          }
          return [...list];
        });
      })
      .catch((err) => {
        setUploadList((list) => {
          const idx = list.findIndex(
            (item: any) => item.id === options.file.uid
          );
          const copyItem = { ...list[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";
          if (idx > -1) {
            return [...list.slice(0, idx), copyItem, ...list.slice(idx + 1)];
          }
          return [...list];
        });
      });
  };

  const handleSubmit = (
    comment: any,
    reloadTicket = false,
    leaveTicket = false
  ): Promise<any> => {
    const tikcetId = ticket?.id;

    return createTicketCommentAction(tikcetId, comment)
      .then((res: any) => {
        // reloadTicket
        if (leaveTicket) {
          openNotificationWithIcon("success", "Zostałeś wypisany z wątku");
          getTicketCommentsAction(tikcetId);
          return history.replace(`/group/${ticket?.group?.id}`);
        }
        return getTicketAction(tikcetId, true).then((response: any) => {
          fetchAttachments();
          return getTicketCommentsAction(tikcetId, true).then(
            (commentsRes: any) => {
              setLoggedUserAction({ archive: true });
              setScratchpad({ comment: null, hidden: null });
              openNotificationWithIcon("success", "Komentarz dodany");
              return Promise.resolve(commentsRes);
            }
          );
        });
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
        }
        return Promise.reject(err);
      });
  };

  const handleAutoSave = (comment: any): Promise<any> => {
    if (isCreateCommentRequest) {
      return Promise.reject();
    }

    const ticketId = ticket?.id;

    return TicketService.createScratchpad(ticketId, comment)
      .then((res: any) => {})
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };

  const handleSubmitMiniForm = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    const tikcetId = ticket?.id;
    updateTicketAction(tikcetId, vals)
      .then((res: any) => {
        getTicketAction(tikcetId, true);
        fetchAttachments();
        getTicketCommentsAction(tikcetId, true);
        setLoggedUserAction({ archive: true });
        getLastWorktimeAction();
        getActiveWorktimeAction();
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  const handleAddWorktime = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const ticketId = ticket?.id;
    addWorktimeAction(ticketId, values.start, values.seconds, values.comment)
      .then((res: any) => {
        getLastWorktimeAction();
        getActiveWorktimeAction();
        getTicketWorktimesShortAction(ticketId);
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  const handleStartWorktime = (taskId: string | number) => {
    startWorktimeAction(taskId)
      .then(() => {
        getLastWorktimeAction();
        getActiveWorktimeAction();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Nie możesz wystartować czasu jeśli wykonywany jest inny wątek lub jeśli wątek jest zamknięty."
          );
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie masz uprawnień do wystartowania czasu."
          );
        }

        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Nie możesz wystartować czasu w zamkniętym wątku"
          );
        }
      });
  };

  const handleEndWorktime = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const ticketId = ticket?.id;
    endWorktimeAction(values.id, values.comment)
      .then(() => {
        getTicketWorktimesShortAction(ticketId);
        getLastWorktimeAction();
        getActiveWorktimeAction();
      })
      .catch((err: AxiosError) => {
        openNotificationWithIcon("error", err.message);
      });
  };

  const handleTicketLeave = (callback: () => void) => {
    const ticketId = ticket?.id;
    TicketService.leaveTicket(ticketId)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Zostałeś wypisany z wątku");
        getTicketCommentsAction(ticketId);
        history.replace(`/group/${ticket?.group?.id}`);
        callback();
      })
      .catch((error: AxiosError<any>) => {
        if (error) {
          openNotificationWithIcon(
            "error",
            error.response?.data?.message || "Wystąpił problem"
          );
        }
        callback();
      });
  };

  const handleUnread = () => {
    const ticketId = ticket?.id;
    setUnreadLoading(true);
    TicketService.setAsUnread(ticketId).then((response: AxiosResponse) => {
      history.replace(`/group/${groupId}`);
      setUnreadLoading(false);
      openNotificationWithIcon(
        "success",
        "Wątek oznaczony jako nieprzeczytany"
      );
    });
  };

  const toggleCommentsExpand = () => {
    UsersService.updateMeta({ commentsExpanded: !isCommentsExpanded });
    setCommentsExpanded(!isCommentsExpanded);
  };

  const scrollToInformation = () => {
    const element = document.getElementById(`informations`);

    if (!element) return;

    setTimeout(() => {
      requestAnimationFrame(() => {
        const platform = getMobileOperatingSystem();
        if (platform === "iOS") element.scrollIntoView(true);
        else
          element.scrollIntoView({
            block: "start",
            behavior: "auto",
          });
      });
    }, 2);
  };

  useEffect(() => {
    let timeout: any;
    const hasAutostartRole = loggedUser?.role.find(
      (permission: any) => permission.name === "AUTO_START_TIME"
    );
    if (
      ticket &&
      hasAutostartRole &&
      !activeWorktime &&
      ticket.status_id.id < TicketStatus.CLOSED &&
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      canStartTime
    ) {
      timeout = setTimeout(() => {
        const ticketId = ticket?.id;
        startWorktimeAction(ticketId);
      }, (loggedUser.autostartAfterTime || 0) * 1000);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, ticket]);

  useEffect(() => {
    if (id) {
      getTicketAction(id);
      getTicketCommentsAction(id);
      fetchAttachments();
      getTicketWorktimesShortAction(id);
      TicketService.getScratchpad(id).then((response: AxiosResponse) => {
        setScratchpad(response.data);
      });
    }
  }, [
    fetchAttachments,
    getTicketAction,
    getTicketCommentsAction,
    getTicketWorktimesShortAction,
    id,
  ]);

  useEffect(() => {
    if (visibleAssignForm) {
      getUsersAction();
      if (ticket) {
        getLeadersAction(ticket?.group?.id);
        getMembersAction(ticket?.group?.id);
      }
    }
  }, [
    getLeadersAction,
    getMembersAction,
    getUsersAction,
    ticket,
    visibleAssignForm,
  ]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const isXlBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "xl").length;

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setSticky(!!isXlBreakpoint);
    setIsMobile(!isMdBreakpoint);
  }, [screens]);

  useEffect(() => {
    if (ticket) {
      setCurrentTicketTitleAction(`#${ticket?.id} - ${ticket?.title}`);
      setCurrentGroupTitleAction(ticket?.group?.name);
      getTicketTypesAction(ticket?.group?.id);
    }
  }, [
    getTicketTypesAction,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
    ticket,
  ]);

  useEffect(() => {
    return () => {
      clearTicketStateActon();
      clearCommentsStateAction(id);
      setCurrentGroupTitleAction(null);
      setCurrentTicketTitleAction(null);
      setCurrentEntityForbiddenAction(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearTicketStateActon,
    clearCommentsStateAction,
    setCurrentEntityForbiddenAction,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
  ]);

  useEffect(() => {
    const toggled =
      typeof loggedUser?.meta?.commentsExpanded === "boolean"
        ? loggedUser?.meta?.commentsExpanded
        : false;
    setCommentsExpanded(toggled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.meta?.deadlinesVisible]);

  useEffect(() => {
    if (eventId) setLinkId(`event-${eventId}`);
    else if (commentId) setLinkId(`comment-${commentId}`);
    else if (hiddenId) setLinkId(`hidden-${hiddenId}`);
    else setLinkId(null);
  }, [eventId, commentId, hiddenId]);

  const canChangeEstimation = Can({
    entity: ticket,
    type: "ticket_edit_estimation",
    children: <></>,
  });

  const canViewEstimation = Can({
    entity: ticket,
    type: "view_estimation",
    children: <></>,
  });

  const canChangeCost = Can({
    entity: ticket,
    type: "ticket_edit_cost",
    children: <></>,
  });

  const canViewCost = Can({
    entity: ticket,
    type: "ticket_can_view_valuation",
    children: <></>,
  });

  const canEditDueDate = Can({
    entity: ticket,
    type: "ticket_edit_duo_date",
    children: <></>,
  });

  const canViewHistory = Can({
    entity: ticket,
    type: "ticket_update_history",
    children: <></>,
  });

  const canAssignUsers = Can({
    entity: ticket,
    type: "ticket_can_assign_users",
    children: <></>,
  });

  const canEditProgress = Can({
    entity: ticket,
    type: "ticket_edit_progress",
    children: <></>,
  });

  const canStartTime = !!Can({
    entity: ticket,
    type: "worktime_start",
    children: <></>,
  });

  const canViewWorktime = Can({
    entity: ticket,
    type: "ticket_view_worktime_short",
    children: <></>,
  });

  const canSendEmail = Can({
    type: "open_send_email",
    children: <></>,
  });

  const canUnreadTicket = Can({
    entity: ticket,
    type: "ticket_unread",
    children: <></>,
  });

  const canTicketClose = Can({
    entity: ticket,
    type: "ticket_close",
    children: <></>,
  });

  const canTicketOpen = Can({
    entity: ticket,
    type: "ticket_open",
    children: <></>,
  });

  const canTicketLeave = Can({
    entity: ticket,
    type: "ticket_leave",
    children: <></>,
  });

  return (
    <Can renderError type="ticket_view">
      <NoContentError noContent={!isRequest && !ticket}>
        <div className="ticket-page">
          <MetaTitle
            title={
              ticket ? `#${ticket?.id} - ${ticket?.title}` : "Podgląd wątku"
            }
            displayBadge={notifications}
          />

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24} xl={15} xxl={17}>
              <Card bordered={false} size={isMobile ? "small" : "default"}>
                {isRequest === true ? (
                  <>
                    <Skeleton loading={isRequest} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isRequest} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isRequest} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isRequest} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isRequest} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                  </>
                ) : (
                  <>
                    <Row justify="space-between" align="top">
                      <Col xs={24} sm={18} lg={20}>
                        <Row justify="start" align="middle" wrap={false}>
                          {!isMobile && (
                            <Col>
                              <TicketTypeForm
                                groupId={ticket?.group?.id}
                                ticket={ticket}
                                ticketType={ticket?.ticketType}
                                onSubmit={handleSubmitMiniForm}
                              >
                                <TicketType ticketType={ticket?.ticketType} />
                              </TicketTypeForm>
                            </Col>
                          )}
                          <Col>
                            <h2
                              style={{
                                margin: 0,
                                marginLeft: isMobile ? 0 : "8px",
                              }}
                            >
                              <Space className="ant-space-break-words">
                                {ticket?.status_id.id ===
                                  TicketStatus.CLOSED && (
                                  <LockOutlined style={{ color: "red" }} />
                                )}
                                <span>
                                  #{ticket?.id} - {ticket?.title}
                                </span>
                              </Space>
                            </h2>
                          </Col>
                        </Row>
                        {isMobile && (
                          <Row style={{ marginTop: 4, marginBottom: 8 }}>
                            <Col>
                              <TicketTypeForm
                                groupId={ticket?.group?.id}
                                ticket={ticket}
                                ticketType={ticket?.ticketType}
                                onSubmit={handleSubmitMiniForm}
                              >
                                <TicketType ticketType={ticket?.ticketType} />
                              </TicketTypeForm>
                            </Col>
                          </Row>
                        )}
                        {!isMobile ? (
                          <div>
                            <small
                              style={{
                                color: "#666",
                                fontSize: 12,
                                fontWeight: "normal",
                              }}
                            >
                              Utworzono:
                              <strong>
                                {" "}
                                {moment(ticket?.addDate * 1000).format(
                                  "DD.MM.Y HH:mm"
                                )}
                              </strong>
                              , Zaktualizowano:
                              <strong>
                                {" "}
                                {moment(ticket?.lastUpdated * 1000).format(
                                  "DD.MM.Y HH:mm"
                                )}
                              </strong>
                              , Zgłaszający:
                              <strong>
                                {` ${ticket?.reporter.name} ${ticket?.reporter.surname}`}
                              </strong>
                            </small>
                          </div>
                        ) : (
                          <Row>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Utworzono:
                                <strong>
                                  {" "}
                                  {moment(ticket?.addDate * 1000).format(
                                    "DD.MM.Y HH:mm"
                                  )}
                                </strong>
                              </small>
                            </Col>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Zaktualizowano:
                                <strong>
                                  {" "}
                                  {moment(ticket?.lastUpdated * 1000).format(
                                    "DD.MM.Y HH:mm"
                                  )}
                                </strong>
                              </small>
                            </Col>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Zgłaszający:
                                <strong>
                                  {` ${ticket?.reporter.name} ${ticket?.reporter.surname}`}
                                </strong>
                              </small>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col xs={24} sm={6} lg={4}>
                        <Row justify="end" align="top">
                          <Space>
                            <Can
                              entity={ticket?.group}
                              type={isRequest ? "loading" : "ticket_create"}
                            >
                              <Tooltip title="Duplikuj">
                                <Link
                                  to={`/group/${ticket?.group.id}/${id}/duplicate`}
                                >
                                  <Button icon={<CopyOutlined />} />
                                </Link>
                              </Tooltip>
                            </Can>
                            <Can type="ticket_edit" entity={ticket}>
                              <Tooltip title="Edytuj">
                                <Link
                                  to={`/group/${ticket?.group.id}/${id}/edit`}
                                >
                                  <Button icon={<EditOutlined />} />
                                </Link>
                              </Tooltip>
                            </Can>

                            <Can entity={ticket} type="ticket_comment">
                              <Tooltip title="Dodaj załącznik">
                                <Upload
                                  multiple
                                  disabled={isUploading}
                                  listType="text"
                                  showUploadList={false}
                                  customRequest={handleUpload}
                                  onChange={handleChange}
                                >
                                  <Button icon={<PaperClipOutlined />} />
                                </Upload>
                              </Tooltip>
                            </Can>
                            <Can entity={ticket} type="ticket_comment">
                              <Tooltip title="Napisz komentarz">
                                <Button
                                  icon={<CommentOutlined />}
                                  onClick={() =>
                                    placeCaretAtEnd(
                                      scrollRef?.current?.querySelector(
                                        ".ql-editor"
                                      )
                                    )
                                  }
                                />
                              </Tooltip>
                            </Can>
                            {!sticky && (
                              <Tooltip title="Pokaż informacje">
                                <Button
                                  icon={<InfoCircleOutlined />}
                                  onClick={() => scrollToInformation()}
                                />
                              </Tooltip>
                            )}
                          </Space>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    <Space
                      style={{ width: "100%" }}
                      direction="vertical"
                      size="large"
                    >
                      <div className="ticket-page__description">
                        <Row justify="space-between" align="middle">
                          <Col>
                            <h4>Opis</h4>
                          </Col>
                        </Row>
                        <Text>
                          {HTMLReactParser(ticket?.description || "")}
                        </Text>
                      </div>
                      <div>
                        <AttachmentsList
                          isMobile={isMobile}
                          isLoading={isAttachmentsLoading}
                          attachments={
                            ticketAttachments
                              ? [...uploadList, ...ticketAttachments]
                              : uploadList
                          }
                        />
                        <Comments
                          isMobile={isMobile}
                          isSticky={sticky}
                          commentRef={scrollRef}
                          hiddenRef={hiddenRef}
                          scratchpad={scratchpad}
                          ticket={ticket}
                          loggedUser={loggedUser}
                          comments={comments}
                          handleAutoSave={handleAutoSave}
                          isFetching={isCommentsRequest}
                          isSubmitting={isCreateCommentRequest}
                          handleSubmit={handleSubmit}
                          toggleCommentsExpand={toggleCommentsExpand}
                          expanded={isCommentsExpanded}
                          linkId={linkId}
                        />
                      </div>
                    </Space>
                  </>
                )}
              </Card>
            </Col>
            <Col xs={24} lg={24} xl={9} xxl={7}>
              <Sticky top={80} enabled={sticky} bottomBoundary=".ticket-page">
                <Card
                  id="informations"
                  style={{ scrollMarginTop: 70 }}
                  bordered={false}
                  title="Informacje"
                  size={isMobile ? "small" : "default"}
                >
                  {isRequest === true ? (
                    <>
                      <Skeleton loading={isRequest} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                      <Skeleton loading={isRequest} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                      <Skeleton loading={isRequest} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                    </>
                  ) : (
                    <>
                      {/* {ticket?.groupArchivedAt && ( */}
                      {/*  <Row> */}
                      {/*    <Col> */}
                      {/*      <Alert */}
                      {/*        className="announcement" */}
                      {/*        message={`Wątek został zamknięty na skutek archiwizacji działu (${moment( */}
                      {/*          ticket?.groupArchivedAt * 1000 */}
                      {/*        ).format("DD.MM.Y HH:mm")})`} */}
                      {/*        type="error" */}
                      {/*        showIcon */}
                      {/*        style={{ marginBottom: "24px" }} */}
                      {/*        icon={<LockOutlined />} */}
                      {/*      /> */}
                      {/*    </Col> */}
                      {/*  </Row> */}
                      {/* )} */}
                      <Row gutter={[16, 16]} align="top">
                        <Col xs={10} md={6} lg={6} xl={10}>
                          <Text strong>Nazwa</Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Space className="ant-space-break-words">
                            {ticket?.status_id.id === TicketStatus.CLOSED && (
                              <LockOutlined style={{ color: "red" }} />
                            )}
                            <span>
                              #{ticket?.id} - {ticket?.title}
                            </span>
                          </Space>
                        </Col>
                        <Col xs={10} md={6} lg={6} xl={10}>
                          <Text strong>Zgłaszający</Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Space>
                            <Avatar
                              size={24}
                              src={ticket?.reporter.img || null}
                              className="user-app__avatar"
                              style={{
                                color: "#fefefe",
                                backgroundColor: stringToColour(
                                  ticket?.reporter.email || ""
                                ),
                              }}
                            >
                              {makeAvatarName(
                                ticket?.reporter.deleted || false,
                                ticket?.reporter.name,
                                ticket?.reporter.surname
                              )}
                            </Avatar>
                            <Text>
                              {ticket?.reporter.name} {ticket?.reporter.surname}
                            </Text>
                          </Space>
                        </Col>

                        <Col xs={10} md={6} lg={6} xl={10}>
                          <Text strong>Typ wątku</Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <TicketTypeForm
                            groupId={ticket?.group?.id}
                            ticket={ticket}
                            ticketType={ticket?.ticketType}
                            onSubmit={handleSubmitMiniForm}
                          >
                            <TicketType ticketType={ticket?.ticketType} />
                          </TicketTypeForm>
                        </Col>

                        <Col
                          xs={10}
                          md={6}
                          lg={6}
                          xl={10}
                          className="ticket-info__label-col"
                        >
                          <Text strong className="ticket-info__label">
                            Priorytet
                          </Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Space size="small" align="center">
                            <PriorityDropdown
                              ticket={ticket}
                              onSubmit={handleSubmitMiniForm}
                              type="default"
                            />

                            {ticket?.highlight > 0 && (
                              <BookOutlined
                                style={{ color: "green", marginRight: 2 }}
                              />
                            )}
                            {ticket?.favorite > 0 && (
                              <StarOutlined
                                style={{ color: "orange", marginRight: 2 }}
                              />
                            )}
                            {ticket?.callclient > 0 && (
                              <PhoneOutlined
                                style={{ color: "crimson", marginRight: 2 }}
                              />
                            )}
                          </Space>
                        </Col>

                        <Col
                          xs={10}
                          md={6}
                          lg={6}
                          xl={10}
                          className="ticket-info__label-col"
                        >
                          <Text strong className="ticket-info__label">
                            Ostatnia akcja
                          </Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Popover
                            destroyTooltipOnHide
                            style={{ width: "400px" }}
                            placement="bottom"
                            content={<LastActivity id={ticket?.id} />}
                            title="Lista ostatnich akcji"
                            trigger={canViewHistory ? ["click"] : []}
                            getPopupContainer={(trigger) =>
                              trigger.parentElement as HTMLElement
                            }
                          >
                            <Button
                              type="text"
                              style={{
                                padding: 0,
                                pointerEvents: canViewHistory
                                  ? "unset"
                                  : "none",
                              }}
                            >
                              {moment(ticket?.lastUpdated * 1000).format(
                                "DD.MM.Y HH:mm"
                              )}
                            </Button>
                          </Popover>
                        </Col>
                        {(canEditDueDate || ticket?.dueDate) && (
                          <>
                            <Col
                              xs={10}
                              md={6}
                              lg={6}
                              xl={10}
                              className="ticket-info__label-col"
                            >
                              <Text strong className="ticket-info__label">
                                Termin realizacji
                              </Text>
                            </Col>
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <DueDatePopover
                                editable={canEditDueDate}
                                ticket={ticket}
                                onSubmit={handleSubmitMiniForm}
                              />
                              {canEditDueDate && ticket?.dueDate && (
                                <RemoveRecord
                                  title="Czy chcesz usunąć termin?"
                                  onConfirm={(
                                    callbackHidePopover: () => void
                                  ) =>
                                    handleSubmitMiniForm(
                                      { dueDate: null },
                                      callbackHidePopover
                                    )
                                  }
                                  okText="Usuń"
                                  cancelText="Anuluj"
                                  tooltipText="Usuń termin"
                                  additionalClass="dnd-splitter__handle"
                                />
                              )}
                            </Col>
                          </>
                        )}
                        {canChangeEstimation ||
                        (!!canViewEstimation && ticket?.estimation) ? (
                          <>
                            <Col
                              xs={10}
                              md={6}
                              lg={6}
                              xl={10}
                              className="ticket-info__label-col"
                            >
                              <Text strong className="ticket-info__label">
                                Estymacja
                              </Text>
                            </Col>
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <EstimationPopover
                                value={ticket?.estimation}
                                editable={canChangeEstimation}
                                onSubmit={handleSubmitMiniForm}
                              />
                            </Col>
                          </>
                        ) : null}
                        {(canChangeCost || (!!canViewCost && ticket?.paid)) && (
                          <>
                            <Col
                              xs={10}
                              md={6}
                              lg={6}
                              xl={10}
                              className="ticket-info__label-col"
                            >
                              <Text strong className="ticket-info__label">
                                Płatne
                              </Text>
                            </Col>
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <CostPopover
                                ticket={ticket}
                                onSubmit={handleSubmitMiniForm}
                                editable={canChangeCost}
                              >
                                <Button
                                  type="text"
                                  style={{
                                    padding: 0,
                                    pointerEvents: canChangeCost
                                      ? "initial"
                                      : "none",
                                  }}
                                >
                                  {ticket?.paid ? `Tak` : "Nie"}
                                </Button>
                              </CostPopover>
                            </Col>
                          </>
                        )}
                        {!!canViewCost &&
                        ticket?.cost &&
                        ticket.cost !== "0.00" ? (
                          <>
                            <Col
                              xs={10}
                              md={6}
                              lg={6}
                              xl={10}
                              className="ticket-info__label-col"
                            >
                              <Text strong className="ticket-info__label">
                                Koszt
                              </Text>
                            </Col>
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <CostPopover
                                ticket={ticket}
                                onSubmit={handleSubmitMiniForm}
                                editable={canChangeCost}
                              >
                                <Button
                                  type="text"
                                  className={
                                    ticket.pay ? "ticket-info--pay" : ""
                                  }
                                  style={{
                                    padding: 0,
                                    pointerEvents: canChangeCost
                                      ? "initial"
                                      : "none",
                                  }}
                                >
                                  {ticket?.pay === 1 && (
                                    <DollarCircleOutlined />
                                  )}
                                  {ticket?.cost
                                    ? formatMoney(ticket.cost)
                                    : "0.00 zł"}
                                </Button>
                              </CostPopover>

                              {ticket?.cost && canChangeCost ? (
                                <RemoveRecord
                                  title="Czy chcesz usunąć obecną wycenę?"
                                  onConfirm={(
                                    callbackHidePopover: () => void
                                  ) =>
                                    handleSubmitMiniForm(
                                      { cost: null },
                                      callbackHidePopover
                                    )
                                  }
                                  okText="Usuń"
                                  cancelText="Anuluj"
                                  tooltipText="Usuń wycenę"
                                  additionalClass="dnd-splitter__handle"
                                />
                              ) : null}
                            </Col>
                          </>
                        ) : null}
                        <Can type="ticket_valuation_answer" entity={ticket}>
                          <>
                            <Col xs={10} md={6} lg={6} xl={10} />
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <ValuationAnswer
                                handleValuationAnswer={handleSubmitMiniForm}
                              />
                            </Col>
                          </>
                        </Can>
                        <Can type="ticket_new_valuation" entity={ticket}>
                          <>
                            <Col xs={10} md={6} lg={6} xl={10} />
                            <Col xs={14} md={18} lg={16} xl={14}>
                              <CostPopover
                                ticket={ticket}
                                onSubmit={handleSubmitMiniForm}
                                editable={canChangeCost}
                              >
                                <Button type="text" style={{ padding: 0 }}>
                                  <Button
                                    className="jb-button--ghost jb-button--secondary"
                                    ghost
                                  >
                                    Wyceń ponownie
                                  </Button>
                                </Button>
                              </CostPopover>
                            </Col>
                          </>
                        </Can>
                        <Col xs={24} md={6} lg={6} xl={10}>
                          <Text
                            strong
                            onClick={() => {
                              if (canAssignUsers) setVisibleAssignForm(true);
                            }}
                          >
                            Przydzielone do
                          </Text>
                        </Col>
                        <Col xs={24} md={18} lg={16} xl={14}>
                          <ForwardUsers
                            canSendEmail={canSendEmail}
                            ticket={ticket}
                            onSubmit={handleSubmitMiniForm}
                          />
                        </Col>
                        {(canAssignUsers ||
                          canChangeCost ||
                          canEditProgress ||
                          canUnreadTicket ||
                          canTicketOpen ||
                          canTicketClose ||
                          canTicketLeave) && (
                          <>
                            <Col
                              xs={24}
                              md={6}
                              lg={6}
                              xl={10}
                              className="ticket-info__label-col"
                            >
                              <Text strong className="ticket-info__label">
                                Opcje
                              </Text>
                            </Col>
                            <Col xs={24} md={18} lg={16} xl={14}>
                              <Space size="small" align="center" wrap>
                                <Tooltip title="Przydziel">
                                  <Popover
                                    placement="right"
                                    align={{
                                      targetOffset: [0, 130],
                                      ignoreShake: true,
                                    }}
                                    arrowPointAtCenter={false}
                                    overlayClassName="assign-users-popover"
                                    visible={visibleAssignForm}
                                    onVisibleChange={(change) =>
                                      setVisibleAssignForm(change)
                                    }
                                    content={
                                      <AssignUsers
                                        ticket={ticket}
                                        users={users}
                                        isUsersLoading={
                                          isUsersLoading ||
                                          isLeadersLoading ||
                                          isMembersLoading
                                        }
                                        onSubmit={handleSubmitMiniForm}
                                        setPopoverVisible={setVisibleAssignForm}
                                        loggedUser={loggedUser}
                                        leaders={leaders || []}
                                        members={members || []}
                                        canTicketLeave={canTicketLeave}
                                        assignedUsers={ticket?.assignedUsers.map(
                                          (user: any) => {
                                            return {
                                              key: user.id,
                                              typeAccount: user.typeAccount,
                                              tag: user.tag,
                                              label: `${user.name} ${user.surname}`,
                                            };
                                          }
                                        )}
                                      />
                                    }
                                    title="Przydziel użytkowników"
                                    trigger={
                                      canAssignUsers ? "click" : "disabled"
                                    }
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement as HTMLElement
                                    }
                                  >
                                    {canAssignUsers && (
                                      <Button icon={<UserOutlined />} />
                                    )}
                                  </Popover>
                                </Tooltip>
                                <Can type="ticket_edit_cost" entity={ticket}>
                                  <CostPopover
                                    ticket={ticket}
                                    onSubmit={handleSubmitMiniForm}
                                    editable={canChangeCost}
                                  >
                                    <Tooltip title="Wyceń">
                                      <Button icon={<DollarCircleOutlined />} />
                                    </Tooltip>
                                  </CostPopover>
                                </Can>
                                <Can
                                  type="ticket_edit_progress"
                                  entity={ticket}
                                >
                                  <ProgressPopover
                                    ticket={ticket}
                                    progress={ticket?.progress}
                                    onSubmit={handleSubmitMiniForm}
                                  >
                                    <Tooltip title="Zaktualizuj postęp">
                                      <Button icon={<RocketOutlined />} />
                                    </Tooltip>
                                  </ProgressPopover>
                                </Can>
                                <Can type="ticket_unread" entity={ticket}>
                                  <Tooltip title="Oznacz jako nieprzeczytane">
                                    <Button
                                      icon={
                                        isUnreadLoading ? (
                                          <LoadingOutlined spin />
                                        ) : (
                                          <ReadOutlined />
                                        )
                                      }
                                      onClick={handleUnread}
                                    />
                                  </Tooltip>
                                </Can>
                                <Can type="ticket_close" entity={ticket}>
                                  <CloseTicket
                                    onSubmit={handleSubmitMiniForm}
                                  />
                                </Can>
                                <Can type="ticket_open" entity={ticket}>
                                  <OpenTicket onSubmit={handleSubmitMiniForm} />
                                </Can>
                                {canTicketLeave && (
                                  <LeaveTicketButton
                                    title="Czy napewno chcesz opuścić wątek? Jeśli nie jesteś opiekunem nie będziesz mógł dalej śledzić postępów w wątku oraz nie będziesz otrzymywał powiadomień."
                                    onConfirm={(
                                      callbackHidePopover: () => void
                                    ) => handleTicketLeave(callbackHidePopover)}
                                    okText="Tak"
                                    cancelText="Anuluj"
                                    tooltipText="Wypisz się"
                                  />
                                )}
                              </Space>
                            </Col>
                          </>
                        )}

                        <Col
                          xs={10}
                          md={6}
                          lg={6}
                          xl={10}
                          className="ticket-info__label-col"
                        >
                          <Text strong className="ticket-info__label">
                            Postęp
                          </Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <ProgressPopover
                            ticket={ticket}
                            progress={ticket?.progress}
                            onSubmit={handleSubmitMiniForm}
                          >
                            <Button
                              style={{
                                width: "100%",
                                padding: 0,
                                pointerEvents: canEditProgress
                                  ? "initial"
                                  : "none",
                              }}
                              ghost
                              type="text"
                            >
                              <Progress percent={ticket?.progress || 0} />
                            </Button>
                          </ProgressPopover>
                        </Col>
                        {(canStartTime || canViewWorktime) && (
                          <>
                            <Col xs={10} md={6} lg={6} xl={10}>
                              <Text strong>Czas pracy w wątku</Text>
                            </Col>
                            <Col xs={14} md={18} lg={16} xl={14}>
                              {worktimeRequest ? (
                                <Spin indicator={loadingIcon} />
                              ) : (
                                <Row>
                                  {typeof worktime?.total === "number" && (
                                    <Col span={24}>
                                      <Link
                                        to={`/group/${groupId}/${id}/worktimes`}
                                      >
                                        <Tooltip
                                          title={formatSeconds(
                                            worktime?.total,
                                            true
                                          )}
                                        >
                                          Łącznie:{" "}
                                          {formatSeconds(worktime?.total)}
                                        </Tooltip>
                                      </Link>
                                    </Col>
                                  )}
                                  {canStartTime && (
                                    <Col span={24}>
                                      <Tooltip
                                        title={formatSeconds(
                                          worktime?.self,
                                          true
                                        )}
                                      >
                                        <Text>
                                          Moje: {formatSeconds(worktime?.self)}
                                        </Text>
                                      </Tooltip>
                                    </Col>
                                  )}
                                </Row>
                              )}
                              {!isMobile && (
                                <Can type="worktime_start" entity={ticket}>
                                  <Row>
                                    <ToggleTimeButton
                                      ticketId={ticket?.id}
                                      activeWorktime={activeWorktime}
                                      handleStartWorktime={handleStartWorktime}
                                      handleEndWorktime={handleEndWorktime}
                                      loading={toggleRequest}
                                      handleAddWorktime={handleAddWorktime}
                                    />
                                  </Row>
                                </Can>
                              )}
                            </Col>
                            {isMobile && (
                              <Col xs={24}>
                                <Can type="worktime_start" entity={ticket}>
                                  <ToggleTimeButton
                                    ticketId={ticket?.id}
                                    activeWorktime={activeWorktime}
                                    handleStartWorktime={handleStartWorktime}
                                    handleEndWorktime={handleEndWorktime}
                                    loading={toggleRequest}
                                    handleAddWorktime={handleAddWorktime}
                                  />
                                </Can>
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </Card>
              </Sticky>
            </Col>
          </Row>
        </div>
      </NoContentError>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketAction: getTicket,
  getTicketWorktimesShortAction: getTicketWorktimesShort,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getTicketCommentsAction: fetchComments,
  createTicketCommentAction: createComment,
  setCurrentTicketTitleAction: setCurrentTicketTitle,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentEntityForbiddenAction: setCurrentEntityForbidden,
  clearTicketStateActon: clearTicketState,
  clearCommentsStateAction: clearCommentsState,
  updateTicketAction: updateTicket,
  getUsersAction: getUsers,
  getLeadersAction: getTicketGroupLeaders,
  getMembersAction: getTicketGroupMembers,
  startWorktimeAction: startWorktime,
  endWorktimeAction: endWorktime,
  getActiveWorktimeAction: getUserActiveWorktime,
  getLastWorktimeAction: getUserLastWorktimes,
  addWorktimeAction: addWorktime,
  setLoggedUserAction: setLoggedUser,
};

const mapStateToProps = (state: AppState, ownProps: any) => {
  const ticketId = state.ticket.ticket?.id;
  return {
    ticket: state.ticket.ticket,
    notifications: state.notifications.newNotificationIndicator,
    users: state.users.users,
    leaders: state.ticketgroups.ticketgroupLeaders,
    members: state.ticketgroups.ticketgroupMembers,
    comments: ticketId ? state.ticket.comments[ticketId] || [] : [],
    isCommentsRequest: state.ticket.isFetchCommentsRequest,
    isCreateCommentRequest: state.ticket.isCreateCommentRequest,
    worktime: state.worktime.ticketShort,
    worktimeRequest: state.worktime.ticketShortRequest,
    types: state.ticketgroups.ticketgroupTicketTypes,
    isRequest: state.ticket.isRequest,
    isUsersLoading: state.users.isRequest,
    isLeadersLoading: state.ticketgroups.isFetchTicketGroupLeadersRequest,
    isMembersLoading: state.ticketgroups.isFetchTicketGroupMembersRequest,
    loggedUser: state.auth.logged,
    activeWorktime: state.worktime.active,
    toggleRequest: state.worktime.toggleWorktimeRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketPage);
