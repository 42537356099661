// TODO: scroll jest wyłączony bezpośrednio w bibliotece @fullcalendar/interaction/main: isEnabled: false;
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "moment/locale/pl";

import { Badge, Button, Card, Col, Grid, Row, Space, Spin, Switch } from "antd";

import Text from "antd/es/typography/Text";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import plLocale from "@fullcalendar/core/locales/pl";

import moment from "moment";
import { AxiosError } from "axios";
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import List from "./list";
import {
  getTicketsCalendar,
  getTicketTypes,
  updateTicket,
  updateTicketDueDateCalendar,
} from "../../../actions/tickets";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import UsersService from "../../../services/users-service";
import { AccountType } from "../../../types/user";

interface IRouteParams {
  groupId: string;
}
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

const CalendarPage = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    tickets,
    types,
    isRequest,
    loggedUser,
    isCalendarUpdateRequest,
    getTicketsCalendarAction,
    updateTicketDueDateAction,
    getTicketTypesAction,
    updateTicketAction,
    displayNotification,
  } = props;
  const userMeta = loggedUser?.meta;
  const history = useHistory();
  const calendarRef = useRef<any>();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [currentDate, setCurrentDate] = React.useState(moment());
  const [events, setEvents] = React.useState([]);
  const [isMobile, setIsMobile] = React.useState(false);
  const [notScheduled, setNotScheduled] = React.useState([]);

  const [displayAllTickets, setDisplayAllTickets] = React.useState(
    loggedUser?.meta?.calendarDisplayAll || false
  );

  const [prevMonthCount, setPrevMonthCount] = React.useState(0);
  const [nextMonthCount, setNextMonthCount] = React.useState(0);

  const canEditDueDate = Can({
    type: "ticket_edit_duo_date",
    children: <></>,
  });

  const onMetaSave = (meta: any) => {
    return UsersService.updateMeta(meta);
  };

  useEffect(() => {
    getTicketsCalendarAction();
    getTicketTypesAction();
  }, [getTicketTypesAction, getTicketsCalendarAction]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;
    calendarRef.current
      ?.getApi()
      .changeView(!isMdBreakpoint ? "listMonth" : "dayGridMonth");

    setIsMobile(!isMdBreakpoint);
  }, [screens]);

  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(tickets));
    const eventsArr = copy
      .filter((ticket: any) => ticket.dueDate)
      .map((ticket: any) => {
        return {
          id: ticket.id,
          extendedProps: { group: ticket.group?.id },
          title: `#${ticket.id} - ${ticket.title}`,
          start: moment(ticket.dueDate * 1000).toISOString(),
          allDay: true,
          backgroundColor: ticket.ticketType?.color,
          borderColor: ticket.ticketType?.color,
        };
      });

    const withoutDeadline = tickets.filter((ticket: any) => !ticket.dueDate);
    setEvents(eventsArr);
    setNotScheduled(withoutDeadline);
  }, [tickets]);

  useEffect(() => {
    const countNext = events.filter((event: any) =>
      moment(event.start).isSame(moment(currentDate).add(1, "month"), "month")
    ).length;
    setNextMonthCount(countNext);

    const countPrev = events.filter((event: any) =>
      moment(event.start).isSame(
        moment(currentDate).subtract(1, "month"),
        "month"
      )
    ).length;

    setPrevMonthCount(countPrev);
  }, [currentDate, events]);

  useEffect(() => {
    const displayAll = loggedUser?.meta?.calendarDisplayAll || false;
    setDisplayAllTickets(displayAll);
  }, [loggedUser]);

  const prevMonth = () => {
    calendarRef?.current?.getApi().prev();
    const current = calendarRef?.current?.getApi().getDate();
    setCurrentDate(moment(current));
    calendarRef?.current?.getApi().updateSize();
  };

  const nextMonth = () => {
    calendarRef?.current?.getApi().next();
    const current = calendarRef?.current?.getApi().getDate();
    setCurrentDate(moment(current));
    calendarRef?.current?.getApi().updateSize();
  };

  const handleSubmitMiniForm = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    updateTicketAction(entityId, vals)
      .then((res: any) => {
        getTicketsCalendarAction();
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  const handleOwnSwitch = (value: boolean) => {
    setDisplayAllTickets(value);
    onMetaSave({ calendarDisplayAll: value }).then((res: any) => {
      getTicketsCalendarAction();
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.keyCode) {
        case LEFT_ARROW:
          prevMonth();
          break;
        case RIGHT_ARROW:
          nextMonth();
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="calendar-page">
      <MetaTitle title="Kalendarz" displayBadge={displayNotification} />
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card bordered={false}>
            {loggedUser.typeAccount === AccountType.Administrator && (
              <Row justify="end" style={{ marginBottom: 24 }}>
                <Col>
                  <Switch
                    defaultChecked={displayAllTickets}
                    checked={displayAllTickets}
                    checkedChildren="Pokaż wszystkie"
                    unCheckedChildren="Pokaż wszystkie"
                    onChange={handleOwnSwitch}
                  />
                </Col>
              </Row>
            )}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 16 }}
            >
              <Col
                xs={{ order: 1, span: 12 }}
                sm={{ order: 1, span: 12 }}
                md={{ order: 1, span: 8 }}
                xl={{ order: 1, span: 8 }}
              >
                <Row justify="start">
                  <Badge count={prevMonthCount}>
                    <Button onClick={prevMonth}>
                      <Space>
                        <Text> Poprzedni miesiąc </Text>
                      </Space>
                    </Button>
                  </Badge>
                </Row>
              </Col>
              <Col
                xs={{ order: 3, span: 24 }}
                sm={{ order: 3, span: 24 }}
                md={{ order: 2, span: 8 }}
                xl={{ order: 2, span: 8 }}
              >
                <Row justify="center">
                  <Text
                    style={{ fontSize: 24, marginTop: 15, marginBottom: 15 }}
                  >
                    {moment(currentDate).format("MMMM YYYY")}
                  </Text>
                </Row>
              </Col>
              <Col
                xs={{ order: 2, span: 12 }}
                sm={{ order: 2, span: 12 }}
                md={{ order: 3, span: 8 }}
                xl={{ order: 3, span: 8 }}
              >
                <Row justify="end">
                  <Badge count={nextMonthCount}>
                    <Button onClick={nextMonth}>
                      <Space>
                        <Text> Następny miesiąc </Text>
                      </Space>
                    </Button>
                  </Badge>
                </Row>
              </Col>
            </Row>
            <Spin spinning={isRequest}>
              <FullCalendar
                locale={plLocale}
                events={events}
                eventClick={(obj) =>
                  history.push(
                    `/group/${obj.event?.extendedProps.group}/${obj.event?.id}`
                  )
                }
                eventDrop={(info) => {
                  updateTicketDueDateAction(parseInt(info.event.id, 10), {
                    dueDate: moment(info.event.start).unix(),
                  }).catch((error: AxiosError<any>) => {
                    if (error.response?.status === 403) {
                      openNotificationWithIcon(
                        "error",
                        error.response?.data?.detail
                      );
                    }
                  });
                }}
                eventReceive={(info) => {
                  calendarRef.current
                    .getApi()
                    .getEventById(info.event.id)
                    .remove();

                  updateTicketDueDateAction(parseInt(info.event.id, 10), {
                    dueDate: moment(info.event.start).unix(),
                  }).catch((error: AxiosError<any>) => {
                    if (error.response?.status === 403) {
                      openNotificationWithIcon(
                        "error",
                        error.response?.data?.detail
                      );
                    }
                  });
                }}
                plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                headerToolbar={false}
                ref={calendarRef}
                fixedWeekCount={false}
                noEventsText="brak"
                dayCellClassNames="cell"
                scrollTimeReset={false}
                height="auto"
                eventStartEditable={!!canEditDueDate}
                allDayText=""
                initialView="dayGridMonth"
                droppable={!!canEditDueDate}
                editable={!!canEditDueDate}
                dragScroll={false}
              />
            </Spin>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card bordered={false} title="Bez terminu" size="small">
            <List
              isMobile={isMobile}
              editable={!!canEditDueDate}
              types={types}
              tasks={notScheduled}
              groupId={1}
              isRequest={isRequest}
              handleSubmitMiniForm={handleSubmitMiniForm}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  getTicketsCalendarAction: getTicketsCalendar,
  updateTicketDueDateAction: updateTicketDueDateCalendar,
  getTicketTypesAction: getTicketTypes,
  updateTicketAction: updateTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    tickets: state.ticket.tickets,
    types: state.ticket.types,
    isRequest: state.ticket.isTicketsRequest,
    isCalendarUpdateRequest: state.ticket.isCalendarUpdateRequest,
    displayNotification: state.notifications.newNotificationIndicator,
    loggedUser: state.auth.logged,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
