/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Avatar, Button, Card, Col, Row, Skeleton } from "antd";

import {
  BeforeCapture,
  DragDropContext,
  DragStart,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import classnames from "classnames";
import { FormikErrors } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import Meta from "antd/es/card/Meta";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  clearTicketGroupsState,
  createTicketGroup,
  getTicketGroupsAdmin,
  softDeleteTicketGroup,
  updateTicketGroupPositions,
} from "../../../actions/ticketgroups";
import Splitter from "../../TicketGroups/Splitter";

import NewSplitter from "./NewSplitter";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import NewTicketGroup from "./NewTicketGroup";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";

const NOT_ASSIGNED = "not_assigned";

const Container = (props: PropsWithChildren<any>): JSX.Element => {
  const { children, innerRef, isDraggingOver } = props;
  return (
    <div
      ref={innerRef}
      className={classnames({
        "dnd-splitters-container": true,
        "dnd-slitters-container--dragging-over": isDraggingOver,
      })}
    >
      {children}
    </div>
  );
};

const TicketGroups = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    ticketGroups,
    createTicketGroupAction,
    isCreateTicketGroupRequest,
    isFetching,
    softDeleteTicketGroupAction,
    clearTicketGroupsStateAction,
    displayNotification,
  } = props;

  const [tasks, setTasks] = useState<any>({});

  const [splitters, setSplitters] = useState<any>({});
  const [parentSplitter, setParentSplitter] = useState<any>(null);
  const [newSplitterVisible, setNewSplitterVisible] = useState<boolean>(false);
  const [newTicketGroupVisible, setNewTicketGroupVisible] = useState<boolean>(
    false
  );
  const [splittersOrder, setSplittersOrder] = useState<string[]>([]);
  const [draggedSplitter, setDraggedSplitter] = useState<string | null>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);

  const openNewTicketGroupForm = (splitter: any) => {
    setNewTicketGroupVisible(true);
    setParentSplitter(splitter);
  };

  const updatePositions = (
    type: string,
    groupIds: string[],
    parent?: number | string
  ) => {
    const ids = groupIds.map((id: string) => (id === NOT_ASSIGNED ? 0 : id));
    const parentId = parent === NOT_ASSIGNED ? 0 : parent;
    props.updateTicketGroupPositions(type, ids, parentId).then(() => {
      props.getTicketGroups(true);
    });
  };
  useEffect(() => {
    const tasksTemp: any = {};
    const splittersTemp: any = {};
    const splittersOrderTemp: string[] = [];

    if (ticketGroups.length) {
      ticketGroups.forEach((ticketGroup: any) => {
        if (ticketGroup.break) {
          splittersTemp[ticketGroup.id.toString()] = {
            id: ticketGroup.id.toString(),
            title: ticketGroup.name,
            img: ticketGroup.img,
            taskIds: [],
          };
          splittersOrderTemp.push(ticketGroup.id.toString());
        }
      });
      splittersTemp[NOT_ASSIGNED] = {
        id: NOT_ASSIGNED,
        title: "Nieprzydzielone",
        taskIds: [],
      };
      splittersOrderTemp.push(NOT_ASSIGNED);

      ticketGroups.forEach((ticketGroup: any) => {
        if (!ticketGroup.break) {
          tasksTemp[ticketGroup.id.toString()] = {
            id: ticketGroup.id.toString(),
            title: ticketGroup.name,
          };

          if (
            ticketGroup.parent &&
            splittersTemp[ticketGroup.parent.id.toString()]
          ) {
            splittersTemp[ticketGroup.parent.id.toString()].taskIds.push(
              ticketGroup.id.toString()
            );
          } else {
            splittersTemp[NOT_ASSIGNED].taskIds.push(ticketGroup.id.toString());
          }
        }
      });

      updatePositions("splitter", splittersOrderTemp);
      setSplittersOrder(splittersOrderTemp);
      setSplitters(splittersTemp);
      setTasks(tasksTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketGroups.length]);

  useEffect(() => {
    props.getTicketGroups();

    return () => {
      clearTicketGroupsStateAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSplitterCreate = (
    values: any,
    callbackResetForm: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createTicketGroupAction({ ...values, break: true, position: 0 })
      .then((response: AxiosResponse) => {
        setNewSplitterVisible(false);
        callbackResetForm();
        openNotificationWithIcon("success", "Podziałka dodana");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onTicketGroupCreate = (
    values: any,
    callbackResetForm: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createTicketGroupAction({
      ...values,
      break: false,
      position: parentSplitter.taskIds.length,
      parent: parentSplitter.id,
    })
      .then((response: AxiosResponse) => {
        setNewTicketGroupVisible(false);
        setParentSplitter(null);
        callbackResetForm();
        openNotificationWithIcon("success", "Dział dodany.");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onSplitterDelete = (id: string, callbackHidePopover: () => void) => {
    softDeleteTicketGroupAction(id)
      .then(() => {
        openNotificationWithIcon("success", "Podziałka usunięta");
        callbackHidePopover();
      })
      .catch((error: AxiosError) => {
        callbackHidePopover();
      });
  };

  const onTicketGroupDelete = (id: string, callbackHidePopover: () => void) => {
    softDeleteTicketGroupAction(id)
      .then(() => {
        openNotificationWithIcon("success", "Dział usunięty");
        callbackHidePopover();
      })
      .catch((error: AxiosError) => {
        callbackHidePopover();
      });
  };

  const onDragEnd = (result: DropResult) => {
    setDraggedSplitter(null);

    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      if (destination.index === splittersOrder.length - 1) {
        return;
      }

      const newOrder = Array.from(splittersOrder);
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, draggableId);
      setSplittersOrder(newOrder);
      updatePositions("splitter", newOrder);

      return;
    }

    const start = splitters[source.droppableId];
    const finish = splitters[destination.droppableId];

    if (start === finish) {
      const splitter = splitters[source.droppableId];
      const newTaskIds: string[] = Array.from(splitter.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newSplitter = {
        ...splitter,
        taskIds: newTaskIds,
      };
      setSplitters({ ...splitters, [newSplitter.id]: newSplitter });
      updatePositions("groups", newTaskIds, splitter.id);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds: string[] = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    updatePositions("groups", finishTaskIds, newFinish.id);
    setSplitters({
      ...splitters,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    });
  };
  const onDragStart = (result: DragStart) => {
    if (result.type === "column") {
      setDraggedSplitter(result.draggableId);
    }
  };
  const onBeforeCapture = (result: BeforeCapture) => {
    if (result.draggableId) {
      setDraggedSplitter(result.draggableId);
    }
  };

  return (
    <Can renderError type="admin_view">
      <div className="ticket-group-page" ref={pageContainerRef}>
        <MetaTitle title="Działy" displayBadge={displayNotification} />
        <Row gutter={16}>
          <Col span={24}>
            <div className="ticket-groups-page__header-actions">
              <Button
                type="primary"
                onClick={() => setNewSplitterVisible(true)}
              >
                Nowa podziałka
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {!isFetching && (
              <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeDragStart={onDragStart}
                onBeforeCapture={onBeforeCapture}
              >
                <Droppable
                  droppableId="all-splitters"
                  direction="vertical"
                  type="column"
                >
                  {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                  ) => (
                    <Container
                      {...provided.droppableProps}
                      innerRef={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {splittersOrder.map((splitterId, index: number) => {
                        const splitter = splitters[splitterId];
                        const splitterTasks = splitter.taskIds.map(
                          (taskId: any) => tasks[taskId]
                        );

                        if (
                          splitter.id === NOT_ASSIGNED &&
                          splitter.taskIds.length === 0
                        ) {
                          return null;
                        }

                        return (
                          <Splitter
                            key={splitter.id}
                            splitter={splitter}
                            tasks={splitterTasks}
                            index={index}
                            isDragDisabled={splitter.id === NOT_ASSIGNED}
                            setDraggedSplitter={setDraggedSplitter}
                            currentDraggedSplitter={draggedSplitter}
                            setNewTicketGroupVisible={openNewTicketGroupForm}
                            handleSplitterDelete={onSplitterDelete}
                            handleTicketGroupDelete={onTicketGroupDelete}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </Container>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {isFetching && (
              <>
                <Card style={{ width: "100%", marginTop: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
                <Card style={{ width: "100%", marginTop: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
                <Card style={{ width: "100%", marginTop: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </>
            )}
          </Col>
        </Row>
        <NewSplitter
          onCancel={() => {
            setNewSplitterVisible(false);
          }}
          visible={newSplitterVisible}
          isRequest={isCreateTicketGroupRequest}
          onCreate={onSplitterCreate}
        />

        <NewTicketGroup
          onCancel={() => {
            setNewTicketGroupVisible(false);
          }}
          visible={newTicketGroupVisible}
          isRequest={isCreateTicketGroupRequest}
          onCreate={onTicketGroupCreate}
        />
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroups: getTicketGroupsAdmin,
  updateTicketGroupPositions,
  createTicketGroupAction: createTicketGroup,
  softDeleteTicketGroupAction: softDeleteTicketGroup,
  clearTicketGroupsStateAction: clearTicketGroupsState,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticketGroups: state.ticketgroups.ticketgroups,
    isFetching: state.ticketgroups.isFetchTicketGroupsRequest,
    isUpdateTaskGroupPositionsRequest:
      state.ticketgroups.isUpdateTaskGroupPositionsRequest,
    isCreateTicketGroupRequest: state.ticketgroups.isCreateTicketGroupRequest,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketGroups);
