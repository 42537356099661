import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Grid,
  Menu,
  Space,
  Table,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import {
  BookOutlined,
  DollarCircleOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { Priority, PriorityColor } from "../../../types/ticket-group";
import TicketType from "../../Shared/TicketType";
import TicketTypeForm from "../Ticket/QuickForms/TicketTypeForm";
import PriorityDropdown from "../Ticket/QuickForms/PriorityDropdown";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import Can from "../../Shared/Can";
import { formatMoney } from "../../../utils/common";

const { Text } = Typography;

interface ITicketType {
  id: number;
  name: string;
  color: string;
}
interface ITaskList {
  id: number;
  title: string;
  status: number;
  priority: string;
  progress: string;
  estimation: number;
  members: number;
  dueDate: number;
  priority_id: {
    id: number;
  };
  forwarded: number;
  updatedAt: number;
  pay: number;
  favorite: number;
  highlight: number;
  callclient: number;
  newComments: number;
  isVisited: boolean;
  ticketType: ITicketType;
}
const GroupDeadlines = (props: PropsWithChildren<any>): JSX.Element => {
  const { groupId, tasks, isRequest, types, handleSubmitMiniForm } = props;

  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  let columns = [
    {
      title: "Typ",
      dataIndex: "ticketType",
      key: "ticketType",
      width: 130,
      hidden: isMobile,
      render: (ticketType: ITicketType, ticket: any) => (
        <TicketTypeForm
          ticket={ticket}
          groupId={ticket?.group?.id}
          ticketType={ticket?.ticketType}
          onSubmit={handleSubmitMiniForm}
        >
          <TicketType ticketType={ticketType} />
        </TicketTypeForm>
      ),
      sorter: false,
      showSorterTooltip: false,
    },
    {
      title: "Tytuł",
      dataIndex: "title",
      key: "title",
      hidden: isMobile,
      render: (title: string, task: ITaskList) => {
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link
              to={`/group/${groupId}/${task.id}`}
              className="jb-link jb-link--secendary"
            >
              <Space className="ant-space-break-words">
                {task?.highlight > 0 && (
                  <BookOutlined style={{ color: "green" }} />
                )}
                {task?.favorite > 0 && (
                  <StarOutlined style={{ color: "orange" }} />
                )}
                {task?.callclient > 0 && (
                  <PhoneOutlined style={{ color: "crimson" }} />
                )}
                {task?.pay === 1 && <DollarCircleOutlined />}
                <span>
                  <TrimmedTitle title={`#${task.id} - ${title}`} />
                  <span
                    style={{
                      marginLeft: 6,
                    }}
                  >
                    {!task.isVisited && (
                      <Badge
                        count="Nowy"
                        size="default"
                        style={{
                          backgroundColor: "crimson",
                          marginLeft: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <Badge
                      count={task.newComments}
                      size="default"
                      style={{
                        backgroundColor: "rgb(219, 149, 35)",
                        marginLeft: 2,
                        marginBottom: 2,
                      }}
                    />
                  </span>
                </span>
              </Space>
            </Link>
          </Space>
        );
      },
      ellipsis: false,
      sorter: false,
      showSorterTooltip: false,
    },
    {
      title: "Terminy",
      dataIndex: "title",
      key: "ticket",
      hidden: !isMobile,
      render: (title: string, task: ITaskList) => {
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link
              to={`/group/${groupId}/${task.id}`}
              className="jb-link jb-link--secendary"
            >
              <Space className="ant-space-break-words">
                {task?.highlight > 0 && (
                  <BookOutlined style={{ color: "green" }} />
                )}
                {task?.favorite > 0 && (
                  <StarOutlined style={{ color: "orange" }} />
                )}
                {task?.callclient > 0 && (
                  <PhoneOutlined style={{ color: "crimson" }} />
                )}
                {task?.pay === 1 && <DollarCircleOutlined />}
                <span>
                  <TrimmedTitle title={`#${task.id} - ${title}`} />
                  <span
                    style={{
                      marginLeft: 6,
                    }}
                  >
                    {!task.isVisited && (
                      <Badge
                        count="Nowy"
                        size="default"
                        style={{
                          backgroundColor: "crimson",
                          marginLeft: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <Badge
                      count={task.newComments}
                      size="default"
                      style={{
                        backgroundColor: "rgb(219, 149, 35)",
                        marginLeft: 2,
                        marginBottom: 2,
                      }}
                    />
                  </span>
                </span>
              </Space>
            </Link>
            <div>
              {task.dueDate > 0 && (
                <small style={{ marginRight: 7 }}>
                  Termin:{" "}
                  <strong>
                    {moment(task.dueDate * 1000).format("DD.MM.YYYY HH:mm")}
                  </strong>
                </small>
              )}
              <small style={{ marginRight: 7 }}>
                Priorytet: <strong>{Priority[task?.priority_id?.id]}</strong>
              </small>
            </div>
          </Space>
        );
      },
      ellipsis: false,
      sorter: false,
      showSorterTooltip: false,
    },
    {
      title: "Priorytet",
      dataIndex: "priority_id",
      width: 180,
      ellipsis: true,
      key: "priority_id",
      hidden: isMobile,
      render: (priority_id: any, ticket: any) => {
        const { id } = priority_id;
        return (
          <PriorityDropdown
            ticket={ticket}
            onSubmit={handleSubmitMiniForm}
            type="text"
          />
        );
      },
      sorter: false,
      showSorterTooltip: false,
    },

    {
      title: "Termin realizacji",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 250,
      ellipsis: true,
      hidden: isMobile,
      fixed: "right" as const,

      render: (dueDate: number, record: any) => {
        const diff = moment(dueDate * 1000).diff(moment(), "days");
        return (
          <Space
            direction="vertical"
            size="small"
            align="center"
            style={{ textAlign: "center" }}
          >
            <Text>
              {moment(dueDate * 1000).format("DD.MM.YYYY HH:mm")} (
              <Text type={diff <= 0 ? "danger" : undefined}>{diff} dni</Text>)
            </Text>
            <Text />
          </Space>
        );
      },
      sorter: false,
      showSorterTooltip: false,
    },
  ];
  columns = columns.filter((row: any) => row.hidden === false);

  return (
    <Table
      scroll={{ x: isMobile ? "100%" : 800 }}
      style={{ width: "100%" }}
      rowClassName="deadline-table__row"
      locale={{ emptyText: "Brak terminów" }}
      size={isMobile ? "middle" : "small"}
      dataSource={tasks}
      columns={columns}
      pagination={false}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default GroupDeadlines;
