import React from "react";
import {
  AppstoreAddOutlined,
  BellOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EditOutlined,
  MessageOutlined,
  PicRightOutlined,
} from "@ant-design/icons";

type PropsType = {
  icon: string;
};
const PermissionGroupIcon = ({ icon }: PropsType) => {
  switch (icon) {
    case "check_square_outlined":
      return <CheckSquareOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "pic_right_outlined":
      return <PicRightOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "edit_outlined":
      return <EditOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "bell_outlined":
      return <BellOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "appstore_add_outlined":
      return <AppstoreAddOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "dollar_circle_outlined":
      return <DollarCircleOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "clock_circle_outlined":
      return <ClockCircleOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    case "message_outlined":
      return <MessageOutlined style={{ fontSize: 16, marginRight: 4 }} />;
      break;
    default:
      return <CheckSquareOutlined style={{ fontSize: 16, marginRight: 4 }} />;
  }
};

export default PermissionGroupIcon;
