import { AnyAction } from "redux";

import {
  FETCH_LAST_VISITED,
  FETCH_LAST_VISITED_FAILED,
  FETCH_LAST_VISITED_SUCCESS,
  SET_ARCHIVED_COUNT,
  SET_COMPANY_REF,
  SET_CURRENT_ENTITY_FORBIDDEN,
  SET_CURRENT_GROUP_TITLE,
  SET_CURRENT_TICKET_TITLE,
  SET_EDITED_USER,
} from "../../actions/action-types";

export interface GlobalState {
  currentTicketTitle: string | null;
  currentGroupTitle: string | null;
  currentEditedUser: string | null;
  currentEntityForbidden: boolean;
  lastVisited: any;
  lastVisitedLoading: boolean;
  archivedCount: number;
  companyRef: string | null;
}

const initialState: GlobalState = {
  currentGroupTitle: null,
  currentEditedUser: null,
  currentTicketTitle: null,
  currentEntityForbidden: false,
  archivedCount: 0,
  companyRef: null,
  lastVisited: {
    tickets: [],
    groups: [],
  },
  lastVisitedLoading: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_TICKET_TITLE: {
      return {
        ...state,
        currentTicketTitle: payload,
      };
    }
    case SET_CURRENT_GROUP_TITLE: {
      return {
        ...state,
        currentGroupTitle: payload,
      };
    }
    case SET_EDITED_USER: {
      return {
        ...state,
        currentEditedUser: payload,
      };
    }
    case SET_CURRENT_ENTITY_FORBIDDEN: {
      return {
        ...state,
        currentEntityForbidden: payload,
      };
    }
    case SET_ARCHIVED_COUNT: {
      return {
        ...state,
        archivedCount: payload,
      };
    }

    case SET_COMPANY_REF: {
      return {
        ...state,
        companyRef: payload,
      };
    }

    case FETCH_LAST_VISITED: {
      return {
        ...state,
        lastVisitedLoading: true,
      };
    }

    case FETCH_LAST_VISITED_SUCCESS: {
      return {
        ...state,
        lastVisitedLoading: false,
        lastVisited: payload,
      };
    }

    case FETCH_LAST_VISITED_FAILED: {
      return {
        ...state,
        lastVisitedLoading: false,
      };
    }

    default:
      return state;
  }
};
