import { Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import AuthService from "../../services/auth-service";
import {
  LOGOUT,
  SET_ARCHIVED_COUNT,
  SET_COMPANY_REF,
  SET_LOGGED_USER,
  SIGN_UP_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
} from "../action-types";

export const signUp = (
  username: string,
  password: string,
  remember: boolean
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: SIGN_UP_REQUEST,
    payload: true,
  });
  return AuthService.singUp(username, password, remember).then(
    (response) => {
      dispatch({
        type: SIGN_UP_SUCCESS,
        payload: response.data.token,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: SIGN_UP_FAILURE,
      });
      return Promise.reject(error.response);
    }
  );
};

export const setLoggedUser = (options: { archive: boolean }) => (
  dispatch: Dispatch<any>
) => {
  AuthService.getLoggedUser()
    .then((response: AxiosResponse) => {
      dispatch({
        type: SET_LOGGED_USER,
        payload: response.data.user,
      });

      if (options.archive) {
        dispatch({
          type: SET_ARCHIVED_COUNT,
          payload: response.data.newArchived,
        });
      }

      dispatch({
        type: SET_COMPANY_REF,
        payload: response.data.companyRef,
      });

      return Promise.resolve(response.data);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const logout = () => (dispatch: Dispatch<any>) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
