import React, {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import { Checkbox, Form, Input, Select } from "formik-antd";
import { Alert, Button, Col, Modal, Row } from "antd";
import { Formik, FormikValues } from "formik";
import { SettingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { generateRandomString } from "../../../utils/common";
import { INewUserFormProps, INewUserFormValues } from "../../../types/user";

const NewUserForm: React.FC<INewUserFormProps> = (
  props: PropsWithChildren<INewUserFormProps>
): JSX.Element => {
  const { visible, onCancel, onCreate, isRequest, tags } = props;

  const formikRef = useRef<FormikValues>();

  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const initialFormValues: INewUserFormValues = {
    email: "",
    password: "",
    name: "",
    surname: "",
    tag: null,
    phone: "",
    sendCredentials: false,
  };

  const NewUserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Niepoprawny adres email")
      .required("Pole wymagane"),
    password: Yup.string()
      .min(8, "Hasło jest za krótkie. Minimum 8 znaków")
      .required("Pole wymagane"),
    tag: Yup.array()
      .min(1, "Wybierz min 1 etykietę")
      .required("Pole wymagane")
      .nullable(false)
      .typeError("Wybierz min 1 etykietę"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Telefon powinien składać się z samych cyfr")
      .min(9, "Telefon powinien składać się z 9 cyfr")
      .max(9, "Telefon powinien składać się z 9 cyfr")
      .nullable(),
  });

  return (
    <Modal
      visible={visible}
      title="Nowy użytkownik"
      okText="Zapisz użytkownika"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.setFieldTouched("tag");
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        validationSchema={NewUserSchema}
        validateOnBlur
        onSubmit={(values: INewUserFormValues, { resetForm, setErrors }) => {
          onCreate(values, resetForm, setErrors);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Form.Item
              label="E-mail"
              required
              name="email"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Wprowadź email!" }]}
            >
              <Input name="email" required />
            </Form.Item>

            <Form.Item
              label="Imię"
              name="name"
              labelCol={{ span: 24 }}
              rules={[{ required: false }]}
            >
              <Input name="name" />
            </Form.Item>

            <Form.Item
              label="Nazwisko"
              name="surname"
              labelCol={{ span: 24 }}
              rules={[{ required: false }]}
            >
              <Input name="surname" />
            </Form.Item>

            <Form.Item
              label="Etykiety"
              name="tag"
              labelCol={{ span: 24 }}
              required
            >
              <Select
                mode="tags"
                name="tag"
                filterOption={filterOption}
                labelInValue
                options={tags.map((availableTag: any) => {
                  return { value: availableTag.id, label: availableTag.name };
                })}
              />
            </Form.Item>

            <Col span={24}>
              <Alert
                style={{ marginBottom: 16 }}
                message={
                  <>
                    <p>
                      Drogi użytkowniku. Prosimy o zastosowanie się do rządowych
                      zaleceń dotyczących tworzenia bezpiecznych haseł.
                    </p>
                    <a
                      href="https://www.gov.pl/web/baza-wiedzy/jak-tworzyc-bezpieczne-hasla"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.gov.pl/web/baza-wiedzy/jak-tworzyc-bezpieczne-hasla
                    </a>
                  </>
                }
                type="warning"
              />
            </Col>

            <Form.Item
              label="Hasło"
              name="password"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Wprowadź hasło!" }]}
              required
            >
              <Row>
                <Col span={18}>
                  <Input name="password" style={{ flex: 1 }} />
                </Col>
                <Col span={6}>
                  <Button
                    onClick={generatePassword}
                    icon={<SettingOutlined />}
                    style={{ marginLeft: 8 }}
                    block
                  >
                    Generuj
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label="Telefon"
              name="phone"
              labelCol={{ span: 24 }}
              rules={[{ required: false }]}
            >
              <Input name="phone" />
            </Form.Item>

            <Form.Item name="sendCredentials" valuePropName="checked">
              <Checkbox name="sendCredentials">Wyślij login i hasło</Checkbox>
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NewUserForm;
