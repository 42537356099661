/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import classnames from "classnames";
import { EditOutlined } from "@ant-design/icons";
import DragHandleButton from "./DragHandleButton";
import RemoveRecord from "../Shared/RemoveRecord";

const Container = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    id,
    title,
    innerRef,
    isDragging,
    dragHandleProps,
    handleDelete,
  } = props;
  return (
    <div
      className={classnames({
        "dnd-ticket-group": true,
        "dnd-ticket-group--dragging": isDragging,
      })}
      ref={innerRef}
      {...props}
    >
      <DragHandleButton {...dragHandleProps} />
      <Link to={`/group/${id}`} className="jb-link jb-link--secendary">
        #{id} - {title}
      </Link>
      <div className="dnd-ticket-group__actions">
        <RemoveRecord
          title="Czy napewno chcesz usunąć dział? Dział oraz wszystkie należące do niego wątki zostaną zamknięte i zarchiwizowane"
          onConfirm={(callbackHidePopover: () => void) =>
            handleDelete(id, callbackHidePopover)
          }
          okText="Usuń"
          cancelText="Anuluj"
          tooltipText="Usuń dział"
          additionalClass="dnd-splitter__handle"
        />

        <Tooltip
          title="Edytuj dział"
          mouseEnterDelay={1}
          className="dnd-splitter__handle"
        >
          <Link to={`/groups/${id}/edit`}>
            <Button type="default" icon={<EditOutlined />} />
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};
const TicketGroup = (props: PropsWithChildren<any>): JSX.Element => {
  const { title, id, index, isDragDisabled, handleDelete } = props;
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Container
          id={id}
          title={title}
          handleDelete={handleDelete}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          dragHandleProps={provided.dragHandleProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
        />
      )}
    </Draggable>
  );
};

export default TicketGroup;
