import { Button, Col, Empty, Progress, Row, Space, Table, Tooltip } from "antd";
import React, { PropsWithChildren, useState } from "react";
import {
  CloudDownloadOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  RightOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Text from "antd/es/typography/Text";
import Collapsible from "react-collapsible";
import FileDownload from "js-file-download";
import Lightbox from "react-image-lightbox";
import Paragraph from "antd/es/typography/Paragraph"; // This only needs to be imported once in your app
import ReactAudioPlayer from "react-audio-player";
import { formatBytes, isAudioFileType } from "../../../utils/common";
import TicketService from "../../../services/tickets-service";
import FileImage from "../../Shared/FileImage";
import "react-image-lightbox/style.css";
import TrimmedTitle from "../../Shared/TrimmedTitle";

const getImageUrl = async (fileId: any) => {
  const response = await TicketService.previewFile(fileId);
  return response;
};

const AttachmentsList = (props: PropsWithChildren<any>): JSX.Element => {
  const { attachments, isMobile, isLoading } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [images, setImages] = useState<string[]>([]);

  const [downloadId, setDownloadId] = useState<number | null>(null);
  const [previewId, setPreviewId] = useState(null);

  const handlePreview = async (file: any) => {
    setPreviewId(file.id);
    const image = await getImageUrl(file.id);
    setImages([image]);
    setPreviewId(null);
    setPreviewVisible(true);
  };

  const handleDownload = (file: any) => {
    setDownloadId(file.id);
    TicketService.downloadFile(file.id).then((response) => {
      setDownloadId(null);
      FileDownload(response.data, file.fileName);
    });
  };

  const handleDownloadAll = async () => {
    setDownloadId(-1);
    const attachmentsLength = attachments.length;
    let prefetched = 0;
    attachments.forEach((file: any) => {
      TicketService.downloadFile(file.id)
        .then((response) => {
          FileDownload(response.data, file.fileName);
        })
        .finally(() => {
          prefetched += 1;

          if (prefetched === attachmentsLength) {
            setDownloadId(null);
          }
        });
    });
  };

  let columns = [
    {
      title: "Nazwa",
      dataIndex: "fileOriginalName",
      width: 150,
      ellipsis: false,
      maxWidth: 150,
      hidden: !isMobile,
      render: (name: string, col: any) => {
        return isAudioFileType(col.fileMimeType) ? (
          <Space>
            <ReactAudioPlayer
              preload="none"
              controls
              className="ticket-history__audio-player audio-player"
            >
              <source
                type="audio/mp3"
                src={`${process.env.REACT_APP_API_URL}attachments/file/${col.id}`}
              />
            </ReactAudioPlayer>
          </Space>
        ) : (
          <Space>
            <FileImage type={col.fileMimeType} />
            {/* <TrimmedTitle title={name} maxChars={15} /> */}
            <div>
              <Paragraph
                ellipsis={{ rows: 1 }}
                style={{ maxWidth: "250px", marginBottom: 0 }}
              >
                {name}
              </Paragraph>
              <small>
                {moment(col.date * 1000).format("DD.MM.Y HH:mm")},{" "}
                {formatBytes(col.fileSize, 2)}
              </small>
            </div>
          </Space>
        );
      },
    },

    {
      title: "Nazwa",
      dataIndex: "fileOriginalName",
      width: 200,
      hidden: isMobile,
      render: (name: string, col: any) => {
        return isAudioFileType(col.fileMimeType) ? (
          <ReactAudioPlayer
            preload="none"
            controls
            className="ticket-history__audio-player audio-player"
          >
            <source
              type="audio/mp3"
              src={`${process.env.REACT_APP_API_URL}attachments/file/${col.id}`}
            />
          </ReactAudioPlayer>
        ) : (
          <Space>
            <FileImage type={col.fileMimeType} />
            <Text>{name}</Text>
          </Space>
        );
      },
    },
    {
      title: "Rozmiar",
      width: 100,
      hidden: isMobile,
      dataIndex: "fileSize",
      render: (size: number) => formatBytes(size, 2),
    },
    {
      title: "Data dodania",
      dataIndex: "date",
      hidden: isMobile,
      width: 100,
      render: (date: number) => moment(date * 1000).format("DD.MM.Y HH:mm"),
    },
    {
      dataIndex: "status",
      width: 50,
      render: (status: string, file: any) => {
        if (status === "uploading") {
          return (
            <Progress
              percent={file.progress}
              style={{ width: "100%" }}
              status={file.progress === 100 ? "success" : "active"}
              showInfo={false}
            />
          );
        }
        if (status === "error") {
          return (
            <Space
              align="end"
              style={{ width: "100%", justifyContent: "flex-end" }}
            >
              <Tooltip title="Wystąpił problem w trakcie przesyłania załącznika">
                <Button
                  icon={<WarningOutlined style={{ color: "red" }} />}
                  type="ghost"
                  disabled
                />
              </Tooltip>
            </Space>
          );
        }

        return (
          <Space
            align="end"
            style={{ width: "100%", justifyContent: "flex-end" }}
          >
            {(file.fileMimeType === "image/jpeg" ||
              file.fileMimeType === "image/png" ||
              file.fileMimeType === "image/webp") && (
              <Tooltip title="Podejrzyj załacznik">
                <Button
                  icon={
                    previewId === file.id ? (
                      <LoadingOutlined spin />
                    ) : (
                      <EyeOutlined />
                    )
                  }
                  onClick={() => handlePreview(file)}
                />
              </Tooltip>
            )}
            <Tooltip title="Pobierz załacznik">
              <Button
                icon={
                  downloadId === file.id ? (
                    <LoadingOutlined spin />
                  ) : (
                    <CloudDownloadOutlined />
                  )
                }
                onClick={() => handleDownload(file)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  columns = columns.filter((row: any) => !row.hidden);

  return (
    <>
      <Collapsible
        triggerDisabled={!attachments.length}
        open
        transitionTime={200}
        triggerWhenOpen={
          <Row
            style={{ padding: 0, marginBottom: "16px" }}
            justify="space-between"
            align="middle"
          >
            <Col>
              <h4>
                Załączniki{" "}
                {attachments.length ? `(${attachments.length})` : null}
              </h4>
            </Col>
            <Space>
              <Button
                disabled={!attachments.length}
                icon={
                  downloadId === -1 ? (
                    <LoadingOutlined spin />
                  ) : (
                    <CloudDownloadOutlined />
                  )
                }
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownloadAll();
                }}
              >
                Pobierz wszystkie
              </Button>
              <Tooltip title="Zwiń wszystkie">
                <Button
                  icon={<DownOutlined style={{ fontSize: 14 }} />}
                  disabled={!attachments.length}
                />
              </Tooltip>
            </Space>
          </Row>
        }
        trigger={
          <Row style={{ padding: 0 }} justify="space-between" align="middle">
            <Col>
              <h4>
                Załączniki{" "}
                {attachments.length ? `(${attachments.length})` : null}
              </h4>
            </Col>
            <Space>
              <Button
                disabled={!attachments.length}
                icon={<CloudDownloadOutlined />}
                onClick={(event) => event.stopPropagation()}
              >
                Pobierz wszystkie
              </Button>
              <Tooltip title="Pokaż wszystkie">
                <Button
                  icon={<RightOutlined style={{ fontSize: 14 }} />}
                  disabled={!attachments.length}
                />
              </Tooltip>
            </Space>
          </Row>
        }
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Table
          showHeader={attachments.length > 0}
          columns={columns}
          dataSource={attachments}
          size="small"
          rowClassName={(record, index) => {
            if (record.hidden) {
              return "attachments-table__row--hidden";
            }
            return "attachments-table__row";
          }}
          loading={isLoading}
          pagination={false}
          scroll={{ x: 300 }}
          locale={{
            emptyText: (
              <Empty
                description="Brak załączników"
                style={{ overflow: "hidden" }}
              />
            ),
          }}
        />
      </Collapsible>
      {previewVisible && (
        <Lightbox
          reactModalStyle={{ zIndex: 10000 }}
          mainSrc={images[photoIndex]}
          onCloseRequest={() => setPreviewVisible(false)}
          onMovePrevRequest={() => setPhotoIndex(1)}
          onMoveNextRequest={() => setPhotoIndex(0)}
        />
      )}
    </>
  );
};

interface IFileImageProps {
  type: string;
}

export default AttachmentsList;
