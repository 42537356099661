import React, { useState } from "react";
import { Avatar, Button, Space, Tooltip } from "antd";
import {
  CarFilled,
  LoadingOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import moment from "moment";
import {
  makeAvatarName,
  openNotificationWithIcon,
  stringToColour,
} from "../../../../utils/common";
import Can from "../../../Shared/Can";
import { UserFilledIcon } from "../../../Shared/UserFillled";
import { UserOutlinedIcon } from "../../../Shared/UserOutlined";
import { UserIcon } from "../../../Shared/UserIcon";

const ForwardUsers = (props: any) => {
  const { ticket, onSubmit, canSendEmail } = props;

  const [isLoading, setIsLoading] = useState(0);

  const enabled = Can({
    entity: ticket,
    type: "user_forward",
    children: <></>,
  });

  const handleSubmit = (user: any) => {
    if (!enabled) return;
    const id = ticket?.forwarded !== user.id ? user.id : 0;
    setIsLoading(user.id);
    onSubmit({ forwarded: id }, () => {
      setIsLoading(0);
      openNotificationWithIcon(
        "success",
        id
          ? `Przekazano do ${user.name} ${user.surname}`
          : "Usunięto przydzielenie"
      );
    });
  };

  return (
    <Space direction="vertical">
      {ticket?.assignedUsers.map((user: any) => (
        <Space size="small">
          {ticket?.forwarded === user.id ? (
            <Button
              type="link"
              onClick={() => handleSubmit(user)}
              icon={
                <Tooltip title="Przekazano">
                  {isLoading === user.id ? (
                    <LoadingOutlined spin />
                  ) : (
                    <SendOutlined style={{ color: "#4b1182" }} />
                  )}
                </Tooltip>
              }
            />
          ) : (
            <Button
              type="link"
              onClick={() => handleSubmit(user)}
              style={{
                pointerEvents: enabled && !user.deleted ? "initial" : "none",
              }}
              icon={
                <Tooltip
                  title="Przekaż"
                  trigger={enabled && !user.deleted ? ["hover"] : []}
                >
                  {isLoading === user.id ? (
                    <LoadingOutlined spin />
                  ) : (
                    <SendOutlined style={{ color: "gray" }} />
                  )}
                </Tooltip>
              }
            />
          )}
          <UserIcon
            leaders={ticket?.group?.leaders}
            user={user}
            type="button"
          />

          <Avatar
            size={24}
            src={user.img || null}
            className="user-app__avatar"
            style={{
              color: "#fefefe",
              backgroundColor: stringToColour(user.email),
            }}
          >
            {makeAvatarName(user.deleted, user.name, user.surname)}
          </Avatar>
          {canSendEmail ? (
            <a
              href={`mailto:${user.email}?subject=Mignet%20-%20#${ticket.id}%20-%20${ticket.title}&body=Dzień%20dobry`}
              className="jb-link jb-link--block"
            >
              {user.name} {user.surname}
            </a>
          ) : (
            <Text>
              {user.name} {user.surname}
            </Text>
          )}
        </Space>
      ))}
    </Space>
  );
};

export default ForwardUsers;
