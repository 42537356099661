import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { Card, Col, Row, Space, Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill-2";
import { Linkify, Options } from "quill-linkify";

import {
  defaultEditorFormats,
  defaultEditorModules,
} from "../../../../utils/common";
import Can from "../../../Shared/Can";
import QuillEditor from "../../../Shared/Editor";
import { AccountTypeWithHighlight } from "../../../../types/user";

export interface IEditTicketFormValues {
  title: string;
  ticketType: number | null;
  description: string;
  group: string | number | null;
}

export interface ITicketEditProps {
  title?: string;
  ticketType?: any;
  ticketTypes: any;
  description?: string;
  group: string | number | null;
  groups: any[];
  isLoading: boolean;
  onSave: (
    values: IEditTicketFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IEditTicketFormValues>) => void
  ) => void;
  onGroupChange: (id: number) => void;
}

// Then inside the component body

const { Option, OptGroup } = Select;
const EditTicketForm = (props: ITicketEditProps) => {
  const {
    title,
    description,
    isLoading,
    onSave,
    onGroupChange,
    ticketType,
    ticketTypes,
    group,
    groups,
  } = props;
  const initialFormValues: IEditTicketFormValues = {
    title: "",
    description: description || "",
    ticketType: null,
    group: null,
  };
  const [formData, setFormData] = useState<IEditTicketFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);
  const [splitters, setSplitters] = useState<any[]>([]);
  const [splittersOrder, setSplittersOrder] = useState<any[]>([]);
  const NOT_ASSIGNED = "not_assigned";

  useEffect(() => {
    const splittersTemp: any = {};
    const splittersOrderTemp: string[] = [];
    if (groups.length) {
      groups.forEach((ticketGroup: any) => {
        if (ticketGroup.break) {
          splittersTemp[ticketGroup.id.toString()] = {
            id: ticketGroup.id.toString(),
            title: ticketGroup.name,
            img: ticketGroup.img,
            groups: [],
          };
          splittersOrderTemp.push(ticketGroup.id.toString());
        }
      });
      splittersTemp[NOT_ASSIGNED] = {
        id: NOT_ASSIGNED,
        title: "Nieprzydzielone",
        groups: [],
      };
      splittersOrderTemp.push(NOT_ASSIGNED);

      groups.forEach((ticketGroup: any) => {
        if (!ticketGroup.break) {
          if (
            ticketGroup.parent &&
            splittersTemp[ticketGroup.parent.id.toString()]
          ) {
            splittersTemp[ticketGroup.parent.id.toString()].groups.push(
              ticketGroup
            );
          } else {
            splittersTemp[NOT_ASSIGNED].groups.push(ticketGroup);
          }
        }
      });
      setSplitters(splittersTemp);
      setSplittersOrder(splittersOrderTemp);
    }
  }, [groups]);

  useEffect(() => {
    setFormData({
      title: title || "",
      description: description || "",
      ticketType: ticketType?.id,
      group,
    });
  }, [title, description, ticketType, group]);

  useEffect(() => {
    let defaultType = ticketTypes.filter((type: any) => type.defaultType);
    defaultType = defaultType.length ? defaultType[0] : null;

    if (!defaultType) defaultType = ticketTypes.length ? ticketTypes[0] : null;

    setFormData((prevState) => {
      return { ...prevState, ticketType: defaultType?.id || null };
    });
  }, [ticketTypes]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Pole wymagane"),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(
            values: IEditTicketFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Can type="admin_view">
                <Row gutter={16}>
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="group"
                      label="Dział"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        name="group"
                        placeholder="Wybierz dział wątku"
                        onChange={(id) => {
                          setFormData({
                            ...formProps.values,
                            group: id,
                            ticketType: null,
                          });
                          onGroupChange(id);
                        }}
                      >
                        {splittersOrder.map((splitterId, index: number) => {
                          if (!splitters) return null;
                          const splitter = splitters[splitterId];

                          if (splitter.groups.length === 0) {
                            return null;
                          }

                          return (
                            <OptGroup label={splitter.title}>
                              {splitter.groups.map((g: any) => (
                                <Option value={g.id}>
                                  <Space>
                                    <span>
                                      #{g.id} - {g.name}
                                    </span>
                                  </Space>
                                </Option>
                              ))}
                            </OptGroup>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Can>

              <Row gutter={16}>
                <Can type="ticket_edit_type">
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="ticketType"
                      label="Typ wątku"
                      labelCol={{ span: 24 }}
                    >
                      <Select name="ticketType" placeholder="Wybierz typ wątku">
                        {ticketTypes.map((type: any) => (
                          <Option value={type.id}>
                            <Space>
                              <div
                                style={{
                                  backgroundColor: type.color,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                              {type.name}
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Can>
                <Col span={24}>
                  <Form.Item
                    label="Tytuł"
                    name="title"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Opis"
                    name="description"
                    labelCol={{ span: 24 }}
                  >
                    <QuillEditor
                      initValue={initialFormValues.description}
                      value={formProps.values.description}
                      withMedia
                      reinitialize={false}
                      onChange={(change: string) => {
                        const val = change === "<p><br></p>" ? "" : change;
                        formProps.setFieldValue("description", val);
                        formProps.setFieldTouched("description", true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default React.memo(EditTicketForm);
