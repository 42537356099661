/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Popover,
  PopoverProps,
  Row,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  AreaChartOutlined,
  BookOutlined,
  CheckOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DownOutlined,
  DragOutlined,
  NotificationOutlined,
  PhoneOutlined,
  PlusOutlined,
  RightOutlined,
  SettingOutlined,
  StarOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import Collapsible from "react-collapsible";
import HTMLReactParser from "html-react-parser";
import { HashLink } from "react-router-hash-link";
import { FormikErrors } from "formik";
import {
  makeAvatarName,
  mergeByProperty,
  stringToColour,
} from "../../../utils/common";
import { Disposable } from "../../../types/ticket-group";
import TicketGroupUpdateFilter from "../../Shared/CustomFilters/TicketGroupUpdate";
import TicketType from "../../Shared/TicketType";
import Can from "../../Shared/Can";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import TicketTypeForm from "../Ticket/QuickForms/TicketTypeForm";

const Title = (props: any): JSX.Element => {
  const { id, title, image, type, disposable, notPaid, hidden } = props;

  return (
    <div className="home-splitter__title-box">
      {type === "splitter" && (
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Space size="small">
            <Avatar
              src={image}
              size={40}
              className="home-splitter__avatar"
              style={{
                color: "#fefefe",
                backgroundColor: stringToColour(title),
                borderColor: "#fefefe",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              {makeAvatarName(false, title)}
            </Avatar>
            <p className="home-splitter__title">{title}</p>
          </Space>
          <Space>
            {/* <Can type="admin_view"> */}
            {/*  <Button */}
            {/*    icon={<DragOutlined />} */}
            {/*    style={{ marginLeft: 8 }} */}
            {/*    onClick={(event) => event.stopPropagation()} */}
            {/*  /> */}
            {/* </Can> */}
            {hidden ? (
              <Tooltip title="Pokaż działy">
                <Button icon={<DownOutlined style={{ fontSize: 14 }} />} />
              </Tooltip>
            ) : (
              <Tooltip title="Zwiń działy">
                <Button icon={<RightOutlined style={{ fontSize: 14 }} />} />
              </Tooltip>
            )}
          </Space>
        </Row>
      )}
      {type === "group" && (
        <>
          <Link to={`/group/${id}`} className="jb-link jb-link--secendary">
            <Space size="small">
              <Avatar
                src={image}
                size={40}
                className="home-splitter__avatar"
                style={{
                  color: "#fefefe",
                  backgroundColor: stringToColour(title),
                  borderColor: "#fefefe",
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                {makeAvatarName(false, title)}
              </Avatar>

              <p className="home-splitter__title">
                {disposable !== Disposable.Niedotyczy && (
                  <Tooltip
                    title={`Forma rozliczenia: ${Disposable[disposable]}`}
                  >
                    <DollarOutlined
                      key="charts"
                      style={{
                        color: notPaid ? "red" : "green",
                        marginRight: 4,
                      }}
                    />
                  </Tooltip>
                )}
                {title}
              </p>
            </Space>
          </Link>
        </>
      )}
    </div>
  );
};

const HomeTasksGroup = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    group,
    showNewQuickTicketModal,
    showAnnouncementForm,
    handleSubmitTicketType,
    userMeta,
    onMetaSave,
    getTicketGroup,
    onMarkAsRead,
  } = props;
  const [visiblePopover, setVisiblePopover] = useState<number | null>(null);
  const [filter, setFilter] = useState<any>(userMeta?.homeFilters || {});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFilterType = (filterValue: any) => {
    switch (filterValue.type) {
      case 5:
        return "Ostatnio zaktualizowane";
        break;
      case 1:
        return "Tylko nowości";
        break;
      case 2:
        return "Tylko bez odpowiedzi";
        break;
      case 3:
        return `Tylko po ${filterValue.answerCount} odpowiedziach`;
        break;
      case 4:
        return `Bez odpowiedzi od ${filterValue.noAnswerCount} dni`;
        break;
      case 6:
        return "Przydzielone do mnie";
        break;
      default:
        return "Ostatnio zaktualizowane";
    }
  };
  const rows = group.tasks.slice(0, filter[group.id]?.display || 6);

  const handleMarkAsRead = (groupId: number) => {
    setIsLoading(true);
    onMarkAsRead(groupId).then((res: any) => {
      getTicketGroup(group.id).finally(() => {
        setIsLoading(false);
      });
    });
  };

  const handleTicketTypeSubmit = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    handleSubmitTicketType(
      values,
      () => {
        onRequestComplete();
        setIsLoading(true);
        getTicketGroup(group?.id).finally(() => {
          setIsLoading(false);
        });
      },
      setError
    );
  };

  const GroupEditButton = Can({
    type: "admin_view",
    children: (
      <Tooltip title="Edytuj dział">
        <Link to={`/groups/${group.id}/edit`} type="ghost">
          <SettingOutlined key="setting" />
        </Link>
      </Tooltip>
    ),
  });

  const WorktimeButton = Can({
    type: "ticket_group_worktimes_view_button",
    children: (
      <Tooltip title="Czas pracy">
        <Link to={`/group/${group.id}/worktimes`} type="ghost">
          <AreaChartOutlined key="charts" />
        </Link>
      </Tooltip>
    ),
  });

  const NotificationButton = Can({
    type: "ticket_group_edit_announcement",
    entity: group,
    children: (
      <Tooltip
        title={group.announcement ? "Edytuj ogłoszenie" : "Dodaj ogłoszenie"}
      >
        <NotificationOutlined
          key="notify"
          onClick={() => showAnnouncementForm(group, true)}
        />
      </Tooltip>
    ),
  });

  const MarkAsReadButton = Can({
    type: "admin_view",
    children: (
      <Tooltip title="Oznacz jako przeczytane">
        <CheckOutlined
          key="mark-as-read"
          onClick={() => handleMarkAsRead(group.id)}
        />
      </Tooltip>
    ),
  });

  const QuickAddButton = Can({
    type: "ticket_create",
    entity: group,
    children: (
      <Tooltip title="Szybie dodanie wątku">
        <ThunderboltOutlined
          key="quick-add"
          onClick={() => showNewQuickTicketModal(group.id, true)}
        />
      </Tooltip>
    ),
  });

  const AddButton = Can({
    type: "ticket_create",
    entity: group,
    children: (
      <Tooltip title="Dodaj wątek">
        <Link to={`/group/${group.id}/new-ticket`} type="ghost">
          <PlusOutlined key="ellipsis" />
        </Link>
      </Tooltip>
    ),
  });

  const isAdmin = Can({
    type: "admin_view",
    children: <></>,
  });

  const actions = [
    GroupEditButton,
    WorktimeButton,
    NotificationButton,
    MarkAsReadButton,
    QuickAddButton,
    AddButton,
  ];

  const metaFilters = userMeta?.homeFilters?.[group.id] || null;
  actions.sort((a: JSX.Element | null, b: JSX.Element | null): number => {
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    return 0;
  });

  const users = group?.leaders.map((leader: any) => leader.user);
  const members = group?.members.map((member: any) => member.user);

  mergeByProperty(users, members, "id");

  return (
    <Col xs={24} sm={24} lg={12} xl={8}>
      <Card
        type="inner"
        size="default"
        className="home-splitter"
        headStyle={{ padding: "0px 8px" }}
        extra={
          <>
            <Avatar.Group
              maxCount={2}
              size="small"
              maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            >
              {users.map((user: any) => (
                <Tooltip title={`${user.name} ${user.surname}`} placement="top">
                  <HashLink
                    smooth
                    timeout={500000}
                    to={
                      isAdmin
                        ? `/users/edit/${user.id}`
                        : `/group/${group?.id}#supervisor`
                    }
                  >
                    <Avatar
                      size="default"
                      src={user.img || null}
                      style={{
                        backgroundColor: stringToColour(`${user.email}`),
                      }}
                    >
                      {makeAvatarName(user.deleted, user.name, user.surname)}
                    </Avatar>
                  </HashLink>
                </Tooltip>
              ))}
            </Avatar.Group>
          </>
        }
        title={
          <Title
            image={group.img}
            id={group.id}
            title={group.name}
            type="group"
            disposable={group.disposable}
            notPaid={group.notPaid}
          />
        }
        style={{ marginBottom: 16, width: "100%" }}
        actions={actions}
      >
        {group.announcement && (
          <Alert
            message={HTMLReactParser(group.announcement || "")}
            className="announcement"
            type="info"
            showIcon
            icon={<NotificationOutlined />}
          />
        )}
        <Popover
          popupVisible
          style={{ width: "400px" }}
          placement="bottom"
          visible={visiblePopover === group.id}
          onVisibleChange={(visible: boolean) => {
            setVisiblePopover(visible ? group.id : null);
          }}
          content={(popoverProps: PopoverProps) => (
            <TicketGroupUpdateFilter
              type="home"
              initFilters={metaFilters}
              handleChange={(selected: any) => {
                const newFilter = {
                  type: selected.type,
                  display: selected.display,
                  answerCount: selected.answerCount,
                  noAnswerCount: selected.noAnswerCount,
                };
                setFilter({ ...filter, [group.id]: newFilter });
                setIsLoading(true);
                onMetaSave({ homeFilters: { [group.id]: newFilter } }).then(
                  (res: any) => {
                    getTicketGroup(group.id).finally(() => {
                      setIsLoading(false);
                    });
                  }
                );
                setVisiblePopover(null);
              }}
              handleClear={(selected: any) => {
                const newFilter = {
                  type: selected.type,
                  display: selected.display,
                  answerCount: selected.answerCount,
                  noAnswerCount: selected.noAnswerCount,
                };
                setFilter({ ...filter, [group.id]: newFilter });
                setVisiblePopover(null);
              }}
            />
          )}
          title="Wybierz filtr"
          trigger="click"
        >
          <Divider plain>
            <Button type="text">
              <strong>
                {filter[group.id]
                  ? getFilterType(filter[group.id])
                  : "Ostatnio zaktualizowane"}
              </strong>
            </Button>
          </Divider>
        </Popover>
        <Spin spinning={isLoading}>
          {group.tasks.length > 0 ? (
            <>
              {rows.map((task: any) => (
                <div
                  style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    display: "block",
                  }}
                >
                  <Row gutter={8}>
                    <Col xs={8} sm={6} md={4} lg={8} xl={6} xxl={5}>
                      <div>
                        <TicketTypeForm
                          ticket={task}
                          groupId={group?.id}
                          ticketType={task?.ticketType}
                          onSubmit={handleTicketTypeSubmit}
                        >
                          <TicketType ticketType={task.ticketType} />
                        </TicketTypeForm>
                      </div>
                    </Col>

                    <Col xs={16} sm={18} md={20} lg={16} xl={18} xxl={19}>
                      {task?.highlight > 0 && (
                        <BookOutlined
                          style={{ color: "green", marginRight: 2 }}
                        />
                      )}
                      {task?.favorite > 0 && (
                        <StarOutlined
                          style={{ color: "orange", marginRight: 2 }}
                        />
                      )}
                      {task?.callclient > 0 && (
                        <PhoneOutlined
                          style={{ color: "crimson", marginRight: 2 }}
                        />
                      )}
                      {task?.pay === 1 && (
                        <DollarCircleOutlined style={{ marginRight: 4 }} />
                      )}
                      <Link
                        to={`group/${group?.id}/${task.id}`}
                        className="jb-link jb-link--secendary"
                      >
                        <span className="home-splitter__ticket ticket__title">
                          <TrimmedTitle title={`#${task.id} - ${task.title}`} />
                        </span>
                      </Link>
                      <span
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        {!task.isVisited && (
                          <Badge
                            count="Nowy"
                            size="default"
                            style={{
                              backgroundColor: "crimson",
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                        <Badge
                          count={task.newComments}
                          size="default"
                          style={{
                            backgroundColor: "rgb(219, 149, 35)",
                            marginLeft: 2,
                            marginBottom: 2,
                          }}
                        />
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}

              <Divider plain>
                <Link
                  to={`group/${group?.id}`}
                  style={{ fontWeight: 700 }}
                  className="jb-link jb-link--secendary"
                >
                  <Button type="text">
                    <strong>Zobacz wszystkie</strong>
                  </Button>
                </Link>
              </Divider>
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Brak wątków"
              style={{ marginTop: 54, marginBottom: 54 }}
            />
          )}
        </Spin>
      </Card>
    </Col>
  );
};

const HomeSplitter = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    splitter,
    showNewQuickTicketModal,
    handleSubmitTicketType,
    showAnnouncementForm,
    userMeta,
    getTicketGroup,
    onMetaSave,
    onMarkAsRead,
  } = props;

  const handleSplitterCollapse = (splitterId: number, visible: boolean) => {
    onMetaSave({ splittersHomeVisibility: { [splitterId]: visible } });
  };

  const initialOpen =
    typeof userMeta?.splittersHomeVisibility?.[splitter.id] === "boolean"
      ? userMeta?.splittersHomeVisibility?.[splitter.id]
      : true;

  return (
    <Collapsible
      open={initialOpen}
      transitionTime={200}
      onTriggerOpening={() => handleSplitterCollapse(splitter.id, true)}
      onTriggerClosing={() => handleSplitterCollapse(splitter.id, false)}
      triggerWhenOpen={
        <Card
          size="small"
          style={{ marginBottom: 16, background: "rgb(250,250,250)" }}
        >
          <Title
            id={splitter.id}
            title={splitter.title}
            image={splitter.img}
            type="splitter"
            hidden={false}
          />
        </Card>
      }
      trigger={
        <Card size="small" style={{ marginBottom: 16 }}>
          <Title
            id={splitter.id}
            title={splitter.title}
            image={splitter.img}
            type="splitter"
            hidden
          />
        </Card>
      }
    >
      <Row gutter={16}>
        {splitter.groups.map((group: any) => (
          <HomeTasksGroup
            group={group}
            showNewQuickTicketModal={showNewQuickTicketModal}
            showAnnouncementForm={showAnnouncementForm}
            onMetaSave={onMetaSave}
            getTicketGroup={getTicketGroup}
            userMeta={userMeta}
            onMarkAsRead={onMarkAsRead}
            handleSubmitTicketType={handleSubmitTicketType}
          />
        ))}
      </Row>
    </Collapsible>
  );
};

export default HomeSplitter;
