import React, { useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Modal,
  Popover,
  Row,
  Space,
  Tooltip,
  Upload,
} from "antd";
import classnames from "classnames";
import { MentionsInput, Mention } from "react-mentions";
import {
  PaperClipOutlined,
  SendOutlined,
  SmileFilled,
  SmileOutlined,
  SmileTwoTone,
} from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";
import moment from "moment";
import { ReactionBarSelector } from "@charkour/react-reactions";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import {
  defaultReactions,
  emojisCategories,
  makeAvatarName,
  stringToColour,
  timestampToDate,
} from "../../../utils/common";
import TicketService from "../../../services/tickets-service";
import UploadAttachmentsList from "../UplaodAttachmentsList";
import MessageUploadList from "./MessageUploadList";

interface IProps {
  // eslint-disable-next-line react/require-default-props
  users: any[];
  tickets: any[];
  tags: any[];
  isSending: boolean;
  handleSubmit: (message: string, uploadList: [], callback: () => void) => void;
}

export const MessageInput = (props: IProps) => {
  const { users, tickets, tags, isSending, handleSubmit } = props;
  const [value, setValue] = useState<string>("");
  const [uploadList, setUploadList] = useState<any>([]);
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState<boolean>(false);

  const handleAttachmentProgressChange = (file: any, progress: number) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...prevState[idx] };
        copyItem.progress = progress;
        return [
          ...prevState.slice(0, idx),
          copyItem,
          ...prevState.slice(idx + 1),
        ];
      }
      return null;
    });
  };

  const handleAttachmentsRemove = (file: any) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.id);
      if (idx > -1) {
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
      }
      return null;
    });
  };

  const handleAttachmentsChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((prevState: any) => {
      return [item, ...prevState];
    });
  };

  const handleAttachmentsUpload = (options: any) => {
    // setIsUploading(true);
    return TicketService.uploadAttachment(options.file, (change) =>
      handleAttachmentProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              response.data,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      })
      .catch((err) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          const copyItem = { ...prevState[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              copyItem,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      });
  };

  const clearValue = () => {
    setValue("");
    setUploadList([]);
  };

  const handleKeyPress = (
    isShift: boolean,
    keyCode: string,
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (!isShift && keyCode === "Enter") {
      event.preventDefault();
      if (!isSending && value !== "")
        handleSubmit(
          value,
          uploadList
            .filter(
              (file: any) =>
                file.status !== "error" || file.status === "uploading"
            )
            .map((file: any) => file.id),
          clearValue
        );
    }
  };

  return (
    <Row
      gutter={[16, 16]}
      align="middle"
      style={{ marginTop: "auto" }}
      className="mentions chat__input-container"
    >
      {uploadList.length > 0 && (
        <Col flex="100%" style={{ marginTop: 16 }}>
          <MessageUploadList
            displayHeader={false}
            attachments={uploadList}
            handleRemove={handleAttachmentsRemove}
          />
        </Col>
      )}

      <Col flex="100%">
        <Row wrap={false} align="middle" gutter={[16, 16]}>
          <Col flex="40px">
            <Upload
              multiple
              listType="text"
              showUploadList={false}
              customRequest={handleAttachmentsUpload}
              onChange={handleAttachmentsChange}
            >
              <Button
                size="middle"
                shape="circle"
                type="primary"
                icon={<PaperClipOutlined />}
              />
            </Upload>
          </Col>
          <Col flex="auto">
            <MentionsInput
              value={value}
              onChange={(change) => setValue(change.target.value)}
              placeholder="Wprowadź wiadomość"
              a11ySuggestionsListLabel="Suggested mentions"
              forceSuggestionsAboveCursor
              className="mentions chat__input"
              onKeyDown={(event) =>
                handleKeyPress(event.shiftKey, event.key, event)
              }
              onSelect={(event) => console.log(event)}
            >
              <Mention
                markup="@@@____id__^^____display__@@@^^^"
                trigger="@"
                displayTransform={(id, display) => {
                  return `@${display}`;
                }}
                data={[...tags, ...users]}
                appendSpaceOnAdd
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="user">{highlightedDisplay}</div>
                )}
                // onAdd={onAdd}
                // style={defaultMentionStyle}
                className="mentions__mention"
                onAdd={(id, display) => console.log(id, display)}
              />

              <Mention
                markup="###____id__??____display__###^^^"
                trigger="#"
                displayTransform={(id, display) => {
                  return `#${display}`;
                }}
                data={tickets}
                appendSpaceOnAdd
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="user">{highlightedDisplay}</div>
                )}
                // onAdd={onAdd}
                // style={defaultMentionStyle}
                className="mentions__mention"
                onAdd={(id, display) => console.log(id, display)}
              />
            </MentionsInput>

            <Button
              className="chat__input-emoji"
              size="small"
              shape="circle"
              type="text"
              onClick={() => setVisibleEmojiPicker(true)}
              icon={<SmileFilled className="chat__input-emoji-icon" />}
            />
          </Col>
          <Col flex="70px">
            <Button
              loading={isSending}
              onClick={() =>
                handleSubmit(
                  value,
                  uploadList
                    .filter(
                      (file: any) =>
                        file.status !== "error" || file.status === "uploading"
                    )
                    .map((file: any) => file.id),
                  clearValue
                )
              }
              size="large"
              shape="circle"
              type="primary"
              icon={<SendOutlined rotate={0} />}
              disabled={
                (!value && uploadList.length === 0) ||
                (uploadList &&
                  uploadList.findIndex(
                    (attachment: any) => attachment.status === "uploading"
                  ) !== -1)
              }
            />
          </Col>
        </Row>
      </Col>
      <Modal
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={null}
        visible={visibleEmojiPicker}
        onOk={() => setVisibleEmojiPicker(false)}
        onCancel={() => setVisibleEmojiPicker(false)}
      >
        <EmojiPicker
          width="100%"
          skinTonesDisabled
          previewConfig={{ showPreview: false }}
          categories={emojisCategories}
          emojiStyle={EmojiStyle.NATIVE}
          emojiVersion="12"
          searchDisabled
          onEmojiClick={(emoji, event) => {
            setValue((prevValue) => {
              return prevValue + emoji.emoji;
            });
          }}
        />
      </Modal>
    </Row>
  );
};
