/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Badge, Col, Layout, Row, Spin } from "antd";
import { useIdleTimer } from "react-idle-timer";
import {
  Link,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  MenuFoldOutlined,
  MenuOutlined,
  MessageOutlined,
  SearchOutlined,
  UpCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";

import classnames from "classnames";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import ScrollToTopBtn from "react-scroll-to-top";
import logo from "../../assets/images/mignet.png";
import UserApp from "../UserApp/UserApp";
import UserNotifications from "../UserNotifications/UserNotifications";
import UserTimer from "../UserTimer/UserTimer";
import Breadcrumbs from "../Breadcrumbs";
import { logout, setLoggedUser } from "../../actions/auth";
import { AppState } from "../../reducers";
import {
  openNetworkNotification,
  openNotificationWithIcon,
  stringToColour,
} from "../../utils/common";
import Can from "../Shared/Can";
import {
  endWorktime,
  getTicketWorktimesShort,
  getUserActiveWorktime,
  getUserLastWorktimes,
  startWorktime,
} from "../../actions/worktimes";
import {
  getNotifications,
  setNewNotification,
} from "../../actions/notifications";

import NotificationService from "../../services/notification-service";
import SideMenu from "../Shared/SideMenu";
import { RocketIcon } from "../Shared/Rocket";
import { getNewMessagesCount } from "../../actions/chat";
import { AccountType } from "../../types/user";
import AudioService from "../../services/audio-service";
import { getLastVisited } from "../../actions/global";
import Search from "../Shared/Search";
import useIsMobile from "../../hooks/useIsMobile";
import useIsOnline from "../../hooks/useIsOnline";
import usePrevious from "../../hooks/usePrevious";
// import useIdleTimer from "../../hooks/useIdleTimer";

const { Header, Content, Footer } = Layout;
interface IDashboardBaseProps {
  activeWorktime: any;
  lastWorktimes: any[];
  title: string;
  ticket: any;
  notifications: any;
  subtitle?: string;
  logoutAction?: () => void;
  getActiveWorktimeAction: () => void;
  getNotificationsAction: () => Promise<any>;
  getLastWorktimeAction: () => void;
  getNewMessagesCountAction: () => Promise<any>;
  setNewNotificationAction: (isNew: boolean) => void;
  startWorktimeAction: (id: string | number) => Promise<any>;
  getTicketWorktimesShortAction: (id: string | number) => Promise<any>;
  endWorktimeAction: (id: string | number, comment?: string) => Promise<any>;
  setLoggedUserAction: (options: { archive: boolean }) => void;
  getLastVisitedAction: () => Promise<any>;
  logged: any | null;
  companyRef: string | null;
  toggleRequest: boolean;
  notificationsRequest: boolean;
  newNotification: boolean;
  currentTicketTitle?: string | null;
  currentGroupTitle?: string | null;
  currentEditedUser?: string | null;
  archivedCount: number;
  lastVisited: any;
  lastVisitedLoading: boolean;
  newMessagesCount: number;
  displayContentOnly?: boolean;
  displayContentOnlyMobile?: boolean;
  hideScrollToTop?: boolean;
  group: any;
}

const DashboardBase: React.FC<PropsWithChildren<IDashboardBaseProps>> = (
  props: PropsWithChildren<IDashboardBaseProps>
): JSX.Element => {
  const {
    notifications,
    activeWorktime,
    lastWorktimes,
    lastVisited,
    lastVisitedLoading,
    children,
    logoutAction,
    logged,
    currentGroupTitle,
    currentTicketTitle,
    currentEditedUser,
    toggleRequest,
    archivedCount,
    newMessagesCount,
    displayContentOnlyMobile,
    displayContentOnly,
    hideScrollToTop,
    setLoggedUserAction,
    getLastWorktimeAction,
    getActiveWorktimeAction,
    getLastVisitedAction,
    group,
    companyRef,
  } = props;

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [clearing, setClearing] = useState<boolean>(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState<boolean>(
    false
  );
  const history = useHistory();
  const isMobile = useIsMobile();

  const { getRemainingTime, isIdle } = useIdleTimer({
    timeout: 15000,
    throttle: 100,
  });

  const isOnline = useIsOnline(`ping`, isIdle());
  const prevIsOnline = usePrevious(isOnline);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isOnline === false && prevIsOnline === true) {
      openNetworkNotification(
        "error",
        "Brak połączenia internetowego",
        isOnline,
        ""
      );
    }

    if (isOnline === true && prevIsOnline === false) {
      openNetworkNotification(
        "success",
        "Połączenie przywrócone",
        isOnline,
        ""
      );
    }
  }, [isOnline, prevIsOnline]);

  const toggle = (value?: any) => {
    if (typeof value === "boolean") {
      setCollapsed(value);
    } else {
      setCollapsed((prevState) => !prevState);
    }
  };

  const toggleSearch = (value?: boolean) => {
    if (typeof value === "boolean") {
      setMobileSearchVisible(value);
    } else {
      setMobileSearchVisible((prevState) => !prevState);
    }
  };

  const isChatEnabled = (): boolean => {
    const hasChatPermission = logged?.role?.find(
      (permission: any) => permission.name === "ENABLED_CHAT"
    );

    return (
      hasChatPermission || logged?.typeAccount === AccountType.Administrator
    );
  };

  const poolNotifications = () => {
    const {
      getNotificationsAction,
      setNewNotificationAction,
      getNewMessagesCountAction,
    } = props;
    getNotificationsAction().then((response: AxiosResponse) => {
      if (response?.data.sound) {
        setNewNotificationAction(true);
        AudioService.getInstance().play("notification");
        getLastWorktimeAction();
        getActiveWorktimeAction();
      }
    });
    if (isChatEnabled()) {
      // if (history.location.pathname !== "/czat" && isChatEnabled()) {
      getNewMessagesCountAction().then((res) => {
        if (res?.data?.sound) AudioService.getInstance().play("message");
      });
    }
  };

  const clearNotifications = () => {
    setClearing(true);
    NotificationService.clearNotifications().then((response: AxiosResponse) => {
      const { getNotificationsAction, setNewNotificationAction } = props;
      getNotificationsAction().then((res: AxiosResponse) => {
        setClearing(false);
        if (res?.data.sound) {
          setNewNotificationAction(true);
          AudioService.getInstance().play("notification");
        }
      });
    });
  };

  const readNotifications = (id: number) => {
    NotificationService.readNotifications(id).then(
      (response: AxiosResponse) => {
        const { getNotificationsAction, setNewNotificationAction } = props;
        getNotificationsAction().then((res: AxiosResponse) => {
          setNewNotificationAction(false);
          if (res?.data.sound) {
            setNewNotificationAction(true);
            AudioService.getInstance().play("notification");
          }
        });
      }
    );
  };

  const clearMetaTitle = () => {
    const { setNewNotificationAction } = props;
    setNewNotificationAction(false);
  };

  const handleStartWorktime = (id: string | number) => {
    const { startWorktimeAction } = props;

    startWorktimeAction(id)
      .then(() => {
        getLastWorktimeAction();
        getActiveWorktimeAction();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Nie możesz wystartować czasu jeśli wykonywany jest inny wątek."
          );
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie masz uprawnień do wystartowania czasu."
          );
        }

        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Nie możesz wystartować czasu w zamkniętym wątku"
          );
        }
      });
  };

  const handleEndWorktime = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { ticket, endWorktimeAction, getTicketWorktimesShortAction } = props;

    endWorktimeAction(values.id, values.comment)
      .then(() => {
        getLastWorktimeAction();
        getActiveWorktimeAction();
        if (ticket) {
          getTicketWorktimesShortAction(ticket?.id);
        }
        if (onRequestComplete) onRequestComplete();
      })
      .catch((err: AxiosError<any>) => {
        if (err.response?.status === 400) {
          openNotificationWithIcon("error", err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    setLoggedUserAction({ archive: history.location.pathname !== "/archive" });
    getLastWorktimeAction();
    getActiveWorktimeAction();
    poolNotifications();

    const unlisten = history.listen((locat) => {
      setLoggedUserAction({ archive: locat.pathname !== "/archive" });
      getLastWorktimeAction();
      getActiveWorktimeAction();
    });
    return () => {
      unlisten();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setInterval(
      () => poolNotifications(),
      parseInt(
        process.env.REACT_APP_DEFAULT_NOTIFICATION_INTERVAL || "2000",
        10
      )
    ); // 5000
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, logged]);

  const hideContent =
    (!!displayContentOnly || (!!isMobile && !!displayContentOnlyMobile)) !==
    false;

  const hideBreadcrumbs = (!!isMobile && !!displayContentOnlyMobile) !== false;

  if (!logged) {
    return (
      <Row justify="center" align="middle" className="main-spinner">
        <Col span={1}>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <Layout className="dashboard">
      <Header
        className="dashboard__main-header main-header"
        style={{
          padding: 0,
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
        }}
      >
        {!mobileSearchVisible && (
          <div className="main-header__logo-container">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <div
              className="user-notifications__link main-header__only-mobile"
              onClick={toggle}
            >
              {collapsed && (
                <MenuOutlined style={{ fontSize: "20px", color: "white" }} />
              )}
              {!collapsed && (
                <MenuFoldOutlined
                  style={{ fontSize: "20px", color: "white" }}
                />
              )}
            </div>
            <Link to="/">
              <img src={logo} className="main-header__logo" alt="logo" />
            </Link>
          </div>
        )}
        <div className="main-header__header-container">
          <Search
            isMobile={isMobile || false}
            isToggled={mobileSearchVisible}
            toggleSearch={toggleSearch}
            lastVisited={lastVisited}
            lastVisitedLoading={lastVisitedLoading}
            getLastVisitedAction={getLastVisitedAction}
          />
          {!mobileSearchVisible && (
            <div className="main-header__actions">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="user-notifications__link main-header__only-mobile"
                onClick={() => toggleSearch()}
              >
                <SearchOutlined style={{ fontSize: "20px", color: "white" }} />
              </a>
              <Can type="chat" key="/czat">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="user-notifications__link">
                  <Link to="/czat" className="user-notifications__link">
                    <Badge count={newMessagesCount} size="small">
                      <MessageOutlined
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </Badge>
                  </Link>
                </a>
              </Can>
              <Can type="has_start_time_permission">
                <UserTimer
                  isToggling={toggleRequest}
                  onStartWorktime={handleStartWorktime}
                  onEndWorktime={handleEndWorktime}
                  timerTasks={activeWorktime ? [activeWorktime] : []}
                  latest={lastWorktimes}
                />
              </Can>
              <UserNotifications
                notifications={notifications?.data}
                clear={clearNotifications}
                isClearing={clearing}
                read={readNotifications}
                clearMetaTitle={clearMetaTitle}
              />
              <UserApp
                user={{
                  name: logged.name,
                  email: logged.email,
                  surname: logged.surname,
                  image: logged.img,
                  typeAccount: logged.typeAccount,
                  tags: logged.tag,
                  avatarColor: "#fefefe",
                  avatarBackground: stringToColour(logged.email),
                }}
                logout={logoutAction}
              />
            </div>
          )}
        </div>
      </Header>
      <Layout className="dashboard__main">
        <SideMenu
          location={history.location}
          collapsed={collapsed}
          archivedCount={archivedCount}
          newMessagesCount={newMessagesCount}
          toggle={toggle}
        />

        {!collapsed && (
          <div className="dashboard__overlay" onClick={() => toggle(true)} />
        )}
        <Content
          className={classnames({
            "dashboard--main-content": true,
            "main-content": true,
            "main-content--content-only": hideContent,
          })}
        >
          {!hideBreadcrumbs ? (
            <Breadcrumbs
              currentEditedUser={currentEditedUser}
              currentTicketTitle={currentTicketTitle}
              currentGroupTitle={currentGroupTitle}
              disabledLink={group?.break}
            />
          ) : null}
          {children}
        </Content>
      </Layout>
      {!hideContent ? (
        <Footer className="dashboard__footer">
          <Row>
            <Col span={24}>
              <p className="dashboard__copyright">
                &copy; {currentYear} Mignet v0.0.103
              </p>
            </Col>
          </Row>
        </Footer>
      ) : null}
      {!hideScrollToTop && (
        <ScrollToTopBtn
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            background: "#4b1182",
            transition: "all 0.4s ease-in",
          }}
          smooth
          viewBox="0 0 0 0"
          component={<UpOutlined style={{ fontSize: "20px", color: "#fff" }} />}
        />
      )}
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
DashboardBase.defaultProps = {
  title: undefined,
  subtitle: undefined,
  logoutAction: undefined,
};

const mapStateToProps = (state: AppState) => {
  return {
    archivedCount: state.global.archivedCount,
    companyRef: state.global.companyRef,
    lastVisited: state.global.lastVisited,
    lastVisitedLoading: state.global.lastVisitedLoading,
    newMessagesCount: state.chat.newMessagesCount,
    activeWorktime: state.worktime.active,
    activeRequest: state.worktime.activeRequest,
    lastWorktimes: state.worktime.last,
    lastRequest: state.worktime.lastRequest,
    toggleRequest: state.worktime.toggleWorktimeRequest,
    logged: state.auth.logged,
    group: state.ticketgroups.ticketgroup,
    currentTicketTitle: state.global.currentTicketTitle,
    currentGroupTitle: state.global.currentGroupTitle,
    currentEditedUser: state.global.currentEditedUser,
    ticket: state.ticket.ticket,
    notificationsRequest: state.notifications.isFetchRequest,
    notifications: state.notifications.notifications,
    newNotification: state.notifications.newNotificationIndicator,
  };
};

const mapDispatchToProps = {
  logoutAction: logout,
  setLoggedUserAction: setLoggedUser,
  getActiveWorktimeAction: getUserActiveWorktime,
  getNotificationsAction: getNotifications,
  getLastWorktimeAction: getUserLastWorktimes,
  getTicketWorktimesShortAction: getTicketWorktimesShort,
  startWorktimeAction: startWorktime,
  endWorktimeAction: endWorktime,
  setNewNotificationAction: setNewNotification,
  getNewMessagesCountAction: getNewMessagesCount,
  getLastVisitedAction: getLastVisited,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBase);
