import React, { PropsWithChildren, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import Icon, {
  AccountBookFilled,
  BookFilled,
  BookOutlined,
  CarFilled,
  DollarCircleOutlined,
  ExceptionOutlined,
  FieldTimeOutlined,
  PercentageOutlined,
  PhoneFilled,
  PhoneOutlined,
  SendOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { Priority, PriorityColor } from "../../../types/ticket-group";
import TicketGroupUpdateFilter from "../../Shared/CustomFilters/TicketGroupUpdate";
import TicketType from "../../Shared/TicketType";
import TicketTypeForm from "../Ticket/QuickForms/TicketTypeForm";
import { formatMoney, formatSeconds } from "../../../utils/common";
import PriorityDropdown from "../Ticket/QuickForms/PriorityDropdown";
import UserOutlined, { UserOutlinedIcon } from "../../Shared/UserOutlined";
import UserFilled, { UserFilledIcon } from "../../Shared/UserFillled";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import Can from "../../Shared/Can";
import { UserIcon } from "../../Shared/UserIcon";

interface ITicketType {
  id: number;
  name: string;
  color: string;
}
interface ITaskList {
  id: number;
  title: string;
  status: number;
  priority_id: {
    id: number;
  };
  progress: number;
  estimation: number;
  members: number;
  forwarded: number;
  lastUpdated: number;
  reporter: any;
  dueDate: any;
  cost: any;
  addDate: any;
  ticketType: ITicketType;
  pay: number;
  favorite: number;
  highlight: number;
  group: {
    id: number;
    leaders: any[];
    name: string;
  };
  callclient: number;
  newComments: number;
  isVisited: boolean;
  worktimeSeconds: number;
  assignedUsers: any[];
}
const TicketList = (props: PropsWithChildren<any>): JSX.Element => {
  const { tasks, isRequest, handleSubmitMiniForm, isMobile } = props;
  const [visiblePopover, setVisiblePopover] = useState<number | null>(null);

  const getCustomFilter = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <Row gutter={[16, 16]} style={{ padding: "8px" }}>
        <Col xs={24}>
          <TicketGroupUpdateFilter
            handleChange={(selected: any) => {
              setSelectedKeys([selected]);
              confirm();
            }}
            handleClear={clearFilters}
          />
        </Col>
      </Row>
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => console.log(visible), 100);
      }
    },
  });

  let columns = [
    {
      title: "Lista wątków",
      dataIndex: "title",
      className: "table__column",
      width: 100,
      key: "ticket",
      hidden: !isMobile,
      render: (title: string, task: ITaskList) => {
        return (
          <Row>
            <Col xs={24}>
              <Space direction="vertical" className="ant-space-break-words">
                <Link
                  to={`/group/${task.group?.id}/${task.id}`}
                  className="jb-link jb-link--secendary"
                >
                  <Space className="ant-space-break-words">
                    <span>
                      <TrimmedTitle title={`#${task.id} - ${task.title}`} />
                      <span
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        {!task.isVisited && (
                          <Badge
                            count="Nowy"
                            size="default"
                            style={{
                              backgroundColor: "crimson",
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                        <Badge
                          count={task.newComments}
                          size="default"
                          style={{
                            backgroundColor: "rgb(219, 149, 35)",
                            marginLeft: 2,
                            marginBottom: 2,
                          }}
                        />
                      </span>
                    </span>
                  </Space>
                </Link>
              </Space>
            </Col>
            <Col xs={24} style={{ marginTop: 4, marginBottom: 4 }}>
              <div
                style={{
                  width: "calc(100vw - 100px)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "50%", marginBottom: "-5px" }}>
                  <TicketType ticketType={task.ticketType} />
                </div>
                <div
                  style={{
                    maxWidth: "50%",
                    display: "flex-inline",
                    flexDirection: "column",
                  }}
                >
                  <PriorityDropdown
                    ticket={task}
                    onSubmit={handleSubmitMiniForm}
                    type="text"
                  />
                </div>
              </div>
            </Col>

            <Col xs={24}>
              <Space direction="vertical" className="table__ticket-info">
                {task.group ? (
                  <Link
                    to={`/group/${task.group.id}`}
                    className="jb-link jb-link--secendary"
                  >
                    <small>
                      Dział: <strong>{task.group.name}</strong>
                    </small>
                  </Link>
                ) : null}
                <small>
                  Dodane{" "}
                  <strong>
                    {moment(task.addDate * 1000).format("DD.MM.YYYY HH:mm")}
                  </strong>{" "}
                  przez{" "}
                  <strong>
                    {" "}
                    {task.reporter?.name} {task.reporter?.surname}
                  </strong>
                </small>
                <small>
                  Ostatnia aktualizacja{" "}
                  <strong>
                    {moment(task.lastUpdated * 1000).format("DD.MM.YYYY HH:mm")}
                  </strong>{" "}
                </small>
              </Space>
            </Col>
            <Col xs={24} style={{ marginTop: 8 }}>
              <Space direction="vertical">
                {[...task.assignedUsers]
                  .sort((a: any, b: any) => {
                    return a.typeAccount - b.typeAccount >= 0 ? -1 : 1;
                  })
                  .filter((user) => user.id === task.forwarded)
                  .map((user: any) => (
                    <Space direction="horizontal">
                      {user.id === task.forwarded && (
                        <Tooltip title="Przekazano">
                          <SendOutlined style={{ color: "#4b1182" }} />
                        </Tooltip>
                      )}
                      <UserIcon leaders={task?.group?.leaders} user={user} />
                      <Space direction="horizontal">
                        <span>
                          {user.name} {user.surname}
                        </span>
                      </Space>
                    </Space>
                  ))}
              </Space>
            </Col>
          </Row>
        );
      },
      ellipsis: false,
      sorter: (a: ITaskList, b: ITaskList) => {
        if (!a.title || !b.title) return -1;
        return a.title.localeCompare(b.title);
      },
      showSorterTooltip: false,
    },
    {
      title: "Typ",
      dataIndex: "ticketType",
      key: "ticketType",
      width: 130,
      hidden: isMobile,
      render: (ticketType: ITicketType, ticket: any) => (
        <TicketType ticketType={ticketType} />
      ),
      sorter: (a: ITaskList, b: ITaskList) => {
        if (!a.ticketType?.name || !b.ticketType?.name) return -1;
        return a.ticketType?.name.localeCompare(b.ticketType?.name);
      },
      showSorterTooltip: false,
    },
    {
      title: "Tytuł",
      dataIndex: "title",
      key: "title",
      width: 230,
      hidden: isMobile,
      className: "table__column--title",
      render: (title: string, task: ITaskList) => {
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link
              to={`/group/${task?.group?.id}/${task.id}`}
              className="jb-link jb-link--secendary"
            >
              <Space className="ant-space-break-words">
                {task?.highlight > 0 && (
                  <BookOutlined style={{ color: "green" }} />
                )}
                {task?.favorite > 0 && (
                  <StarOutlined style={{ color: "orange" }} />
                )}
                {task?.callclient > 0 && (
                  <PhoneOutlined style={{ color: "crimson" }} />
                )}
                {task?.pay === 1 && <DollarCircleOutlined />}
                <span>
                  <TrimmedTitle title={`#${task.id} - ${task.title}`} />
                  <span
                    style={{
                      marginLeft: 6,
                    }}
                  >
                    {!task.isVisited && (
                      <Badge
                        count="Nowy"
                        size="default"
                        style={{
                          backgroundColor: "crimson",
                          marginLeft: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <Badge
                      count={task.newComments}
                      size="default"
                      style={{
                        backgroundColor: "rgb(219, 149, 35)",
                        marginLeft: 2,
                        marginBottom: 2,
                      }}
                    />
                  </span>
                </span>
              </Space>
            </Link>
            <small>
              Dodane{" "}
              <strong>
                {moment(task.addDate * 1000).format("DD.MM.YYYY HH:mm")}
              </strong>{" "}
              przez{" "}
              <strong>
                {" "}
                {task.reporter?.name} {task.reporter?.surname}
              </strong>
              {/* ost. odp.{" "} <strong>Piotr Nowak</strong> */}
            </small>
          </Space>
        );
      },
      ellipsis: false,
      sorter: (a: ITaskList, b: ITaskList) => {
        if (!a.title || !b.title) return -1;
        return a.title.localeCompare(b.title);
      },
      showSorterTooltip: false,
    },
    {
      title: "Dział",
      hidden: isMobile,
      width: 200,
      dataIndex: "group",
      key: "group",
      render: (group: any, task: ITaskList) => {
        const { name, id } = group;
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link to={`/group/${id}`} className="jb-link jb-link--secendary">
              {name}
            </Link>
          </Space>
        );
      },
      ellipsis: false,
      sorter: (a: ITaskList, b: ITaskList) => {
        if (!a.title || !b.title) return -1;
        return a.title.localeCompare(b.title);
      },
      showSorterTooltip: false,
    },
    {
      title: "Priorytet",
      dataIndex: "priority_id",
      width: 170,
      ellipsis: true,
      hidden: isMobile,
      onFilter: (value: string | number | boolean, ticket: ITaskList) =>
        ticket.priority_id?.id === Number(value),
      key: "priority_id",
      filters: [
        { text: "Natychmiastowy", value: 5 },
        { text: "Wysoki", value: 4 },
        { text: "Normalny", value: 3 },
        { text: "Niski", value: 2 },
        { text: "Brak", value: 1 },
      ],
      render: (priority_id: any, ticket: any) => {
        const { id } = priority_id;
        return (
          <PriorityDropdown
            ticket={ticket}
            onSubmit={handleSubmitMiniForm}
            type="text"
          />
        );
      },
      sorter: (a: ITaskList, b: ITaskList) => {
        return a.priority_id.id - b.priority_id.id;
      },
      showSorterTooltip: false,
    },
    {
      title: "Postęp",
      dataIndex: "progress",
      key: "progress",
      hidden: isMobile,
      width: 90,
      sorter: (a: ITaskList, b: ITaskList) => {
        return (a.progress || 0) - (b.progress || 0);
      },
      render: (progress: string, task: ITaskList) => {
        return (
          <Space
            direction="vertical"
            size="small"
            align="center"
            style={{ textAlign: "center", width: "100%" }}
          >
            <Can type="has_start_time_permission">
              <>
                {task.worktimeSeconds > 0 && (
                  <Tooltip
                    title={`Wypracowane: ${formatSeconds(
                      task.worktimeSeconds,
                      true
                    )}`}
                  >
                    <FieldTimeOutlined />
                    <Text> {formatSeconds(task.worktimeSeconds)}</Text>
                  </Tooltip>
                )}
              </>
            </Can>
            <Tooltip title={`Postęp: ${progress || 0}%`}>
              <PercentageOutlined />
              <span> {progress || 0}%</span>
            </Tooltip>
          </Space>
        );
      },
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: "Przydzieleni",
      dataIndex: "assignedUsers",
      key: "assignedUsers",
      hidden: isMobile,
      width: 180,
      render: (assignedUsers: any[], task: ITaskList) => {
        return (
          <Space direction="vertical">
            {[...assignedUsers]
              .sort((a: any, b: any) => {
                return a.typeAccount - b.typeAccount >= 0 ? -1 : 1;
              })
              .map((user: any) => (
                <Space direction="horizontal">
                  <UserIcon leaders={task?.group?.leaders} user={user} />
                  <Space direction="horizontal">
                    <span>
                      {user.name} {user.surname}
                    </span>
                  </Space>
                </Space>
              ))}
          </Space>
        );
      },
      ellipsis: false,
      showSorterTooltip: false,
    },

    {
      title: "Ost. aktualizacja",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      hidden: isMobile,
      width: 170,
      ellipsis: true,
      fixed: "right" as const,
      ...getCustomFilter("test"),

      render: (lastUpdated: number, record: any) => {
        return (
          <Space
            direction="vertical"
            size="small"
            align="center"
            style={{ textAlign: "center" }}
          >
            <Text>{moment(lastUpdated * 1000).format("DD.MM.YYYY HH:mm")}</Text>
            {/* <small> */}
            {/*  Bez odpowiedzi od <br /> */}
            {/*  <strong>10 dni</strong> */}
            {/* </small> */}
          </Space>
        );
      },
      sorter: (a: ITaskList, b: ITaskList) => {
        return a.lastUpdated - b.lastUpdated;
      },
      showSorterTooltip: false,
    },
  ];
  columns = columns.filter((row) => !row.hidden);
  return (
    <Table
      scroll={{ x: isMobile ? "100%" : 1000 }}
      style={{ width: "100%" }}
      locale={{ emptyText: "Brak danych" }}
      className="calendar-page__table table"
      size="middle"
      dataSource={tasks}
      columns={columns}
      pagination={{ position: ["bottomRight"], pageSize: 20 }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default TicketList;
