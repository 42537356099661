import React, { PropsWithChildren, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import { Card, Col, Row, Space } from "antd";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import { getTicketWorktimes } from "../../../actions/worktimes";
import {
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import WorktimesTable from "./WorktimesTable";
import { getTicket } from "../../../actions/tickets";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import WorktimeService from "../../../services/worktime-service";
import { openNotificationWithIcon } from "../../../utils/common";

interface IRouteParams {
  groupId: string;
  id: string;
}

const TicketWorktimesPage = (props: PropsWithChildren<any>): JSX.Element => {
  const [filterVisible, setFilterVisible] = React.useState(false);
  const {
    ticketWorktimes,
    ticketWorktimesRequest,
    ticket,
    displayNotification,
    ticketRequest,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
    setCurrentEntityForbiddenAction,
    getTicketAction,
    getTicketWorktimesAction,
  } = props;

  const { id, groupId } = useParams<IRouteParams>();

  const filterRef = useRef();

  const handleRemove = (worktimeId: string | number) => {
    WorktimeService.removeWorktime(worktimeId).then((response) => {
      openNotificationWithIcon("success", "Czas pracy usunięty");
      getTicketWorktimesAction(id);
    });
  };

  useEffect(() => {
    if (ticket) {
      setCurrentTicketTitleAction(`#${ticket?.id} - ${ticket?.title}`);
      setCurrentGroupTitleAction(ticket?.group?.name);
    }
  }, [setCurrentGroupTitleAction, setCurrentTicketTitleAction, ticket]);

  useEffect(() => {
    if (id) {
      getTicketWorktimesAction(id);
      getTicketAction(id);
    }
  }, [getTicketAction, getTicketWorktimesAction, id]);

  useEffect(() => {
    return () => {
      setCurrentEntityForbiddenAction(false);
    };
  }, [setCurrentEntityForbiddenAction]);

  return (
    <Can renderError type="ticket_worktimes_view">
      <div className="ticket-group-page">
        <MetaTitle
          title="Czas pracy w wątku"
          displayBadge={displayNotification}
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <WorktimesTable
                  data={ticketWorktimes?.data || []}
                  summary={ticketWorktimes?.summary || []}
                  handleRemove={handleRemove}
                  isRequest={ticketWorktimesRequest}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketWorktimesAction: getTicketWorktimes,
  getTicketAction: getTicket,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentTicketTitleAction: setCurrentTicketTitle,
  setCurrentEntityForbiddenAction: setCurrentEntityForbidden,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticket: state.ticket.ticket,
    displayNotification: state.notifications.newNotificationIndicator,
    ticketRequest: state.ticket.isTicketsRequest,
    ticketWorktimes: state.worktime.ticket,
    ticketWorktimesRequest: state.worktime.ticketRequest,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketWorktimesPage);
