import { AxiosResponse } from "axios";
import api, { removeToken, setToken } from "../axiosInstance";

class AuthService {
  singUp = async (
    username: string,
    password: string,
    remember: boolean
  ): Promise<AxiosResponse> => {
    const response = await api.post("login", {
      username,
      password,
      remember,
    });

    if (response.data.token) {
      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
    }

    return response;
  };

  forgotPassword = async (username: string): Promise<AxiosResponse> => {
    const response = await api.post("password/forgot", {
      username,
    });

    // if (response.data.token) {
    //   setToken(response.data.token);
    //   localStorage.setItem("token", response.data.token);
    // }

    return response;
  };

  resetPassword = async (
    password: string,
    repeat_password: string,
    token: string
  ): Promise<AxiosResponse> => {
    const response = await api.post("password/reset", {
      password,
      repeat_password,
      token,
    });

    // if (response.data.token) {
    //   setToken(response.data.token);
    //   localStorage.setItem("token", response.data.token);
    // }

    return response;
  };

  checkResetToken = async (token: string): Promise<AxiosResponse> => {
    const response = await api.post("password/check_token", {
      token,
    });

    return response;
  };

  logout = (): void => {
    removeToken();
    localStorage.removeItem("token");
  };

  getLoggedUser = async (): Promise<AxiosResponse> => {
    const response = await api.get("users/me");
    return response;
  };
}

export default new AuthService();
