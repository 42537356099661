/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";
import { Avatar, Button, Card, Tooltip } from "antd";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import classnames from "classnames";
import { EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TicketGroup from "./TicketGroup";
import DragHandleButton from "./DragHandleButton";
import { makeAvatarName, stringToColour } from "../../utils/common";
import RemoveRecord from "../Shared/RemoveRecord";

const NOT_ASSIGNED = "not_assigned";

const TaskList = (props: PropsWithChildren<any>): JSX.Element => {
  const { children, innerRef, isDraggingOver } = props;
  return (
    <div
      ref={innerRef}
      className={classnames({
        "dnd-splitter__tasks": true,
        "dnd-splitter__tasks--dragging-over": isDraggingOver,
      })}
    >
      {children}
    </div>
  );
};

const Container = (props: PropsWithChildren<any>): JSX.Element => {
  const { children, innerRef } = props;
  return (
    <div
      ref={innerRef}
      {...props}
      className={classnames({
        "dnd-splitter": true,
        "dnd-splitter--dragging-over": true,
      })}
    >
      {children}
    </div>
  );
};

const Title = (props: any): JSX.Element => {
  const {
    id,
    title,
    image,
    dragHandleProps,
    setNewTicketGroupVisible,
    handleDelete,
  } = props;
  return (
    <div className="dnd-splitter__title-box">
      <Avatar
        src={image}
        size={40}
        className="dnd-splitter__avatar"
        style={{
          color: "#fefefe",
          backgroundColor: stringToColour(title),
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {makeAvatarName(false, title)}
      </Avatar>
      <p className="dnd-splitter__title">{title}</p>
      {id !== NOT_ASSIGNED ? (
        <>
          <Tooltip
            title="Dodaj dział"
            mouseEnterDelay={1}
            className="dnd-splitter__handle"
          >
            <Button
              type="primary"
              onClick={() => setNewTicketGroupVisible()}
              style={{
                marginLeft: "auto",
              }}
              icon={<PlusOutlined />}
            />
          </Tooltip>
          <RemoveRecord
            title="Czy napewno chcesz usunąć podziałkę? Działy zostaną przeniesione do sekcji nieprzydzielonych."
            onConfirm={(callbackHidePopover: () => void) =>
              handleDelete(id, callbackHidePopover)
            }
            okText="Usuń"
            cancelText="Anuluj"
            tooltipText="Usuń podziałkę"
            additionalClass="dnd-splitter__handle"
          />
          <Tooltip
            title="Edytuj podziałkę"
            mouseEnterDelay={1}
            className="dnd-splitter__handle"
          >
            <Link to={`/groups/${id}/edit`}>
              <Button type="default" icon={<EditOutlined />} />
            </Link>
          </Tooltip>
          <DragHandleButton
            {...dragHandleProps}
            additionalClass="dnd-splitter__handle"
          />
        </>
      ) : null}
    </div>
  );
};
const Splitter = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    splitter,
    tasks,
    index,
    isDragDisabled,
    setNewTicketGroupVisible,
    currentDraggedSplitter,
    handleSplitterDelete,
    handleTicketGroupDelete,
  } = props;

  return (
    <Draggable
      draggableId={splitter.id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(
        draggableProvided: DraggableProvided,
        draggableSnapshot: DraggableStateSnapshot
      ) => (
        <Container
          innerRef={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <Card
            type="inner"
            title={
              <Title
                id={splitter.id}
                title={splitter.title}
                image={splitter.img}
                dragHandleProps={draggableProvided.dragHandleProps}
                setNewTicketGroupVisible={() =>
                  setNewTicketGroupVisible(splitter)
                }
                handleDelete={handleSplitterDelete}
              />
            }
            className={classnames({
              "dnd-splitter__card": true,
              "dnd-splitter__card--dragging": draggableSnapshot.isDragging,
              "dnd-splitter__card--hidden-body":
                currentDraggedSplitter === splitter.id,
            })}
          >
            {currentDraggedSplitter === splitter.id ? null : (
              <Droppable droppableId={splitter.id}>
                {(
                  provided: DroppableProvided,
                  snaphshot: DroppableStateSnapshot
                ) => (
                  <TaskList
                    innerRef={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    isDraggingOver={snaphshot.isDraggingOver}
                  >
                    {tasks.map((task: any, idx: number) => (
                      <TicketGroup
                        title={task.title}
                        id={task.id}
                        index={idx}
                        key={task.id}
                        handleDelete={handleTicketGroupDelete}
                      />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            )}
          </Card>
        </Container>
      )}
    </Draggable>
  );
};

export default Splitter;
