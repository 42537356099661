import { FormikErrors } from "formik";

export enum AccountType {
  "Użytkownik" = 1,
  "Administrator",
}

export enum AccountTypeWithHighlight {
  "Użytkownik" = 1,
  "Administrator",
  "Opiekun działu",
  "Przydzielony",
}
export interface INewUserFormProps {
  visible: boolean;
  isRequest: boolean;
  tags: any[];
  onCreate: (
    values: INewUserFormValues,
    resetForm: () => void,
    setError: (errors: FormikErrors<INewUserFormValues>) => void
  ) => void;
  onCancel: () => void;
}
export interface INewUserFormValues {
  email: string;
  name: string;
  surname: string;
  tag: any[] | null;
  phone: string;
  password: string;
  sendCredentials: boolean;
}

export interface IUserBasicFormValues {
  email: string | null;
  name: string | null;
  surname: string | null;
  tag: any[];
  phone: string | null;
  typeAccount: string | number;
}

export interface IUserPasswordForm {
  password: string;
}

export interface IUserPasswordFormValues {
  password: string;
}

export interface IUserSettingsForm {
  minTime: number;
  multTime: number;
  autostartAfterTime: number;
  disableEmail: boolean;
}

export interface IUserSettingsFormValues {
  minTime: number;
  multTime: number;
  autostartAfterTime: number;
  disableEmail: boolean;
}

export interface IUserHolidayFormValues {
  holidaysFrom: Date | null;
  holidaysUntil: Date | null;
  holidaysReason: string | null;
  onHolidays: boolean;
}

export interface IUserPermissionForm {
  role: number[];
  allowedAssignionTag: any[];
}

export interface IUserPermissionFormValues {
  role: number[];
  allowedAssignionTag: any[];
}
