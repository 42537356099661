import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb, Col, Row, Spin } from "antd";
import {
  HomeOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";

const Breadcrumbs = (props: any): JSX.Element => {
  const {
    currentTicketTitle,
    currentGroupTitle,
    currentEditedUser,
    disabledLink,
  } = props;

  const loader = (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 14, color: "#666" }} spin />
      }
    />
  );
  const breadcrumbNameMap: any = {
    "/": "Home",
    "/group/:groupId": currentGroupTitle ? (
      <strong className="highlight">{currentGroupTitle}</strong>
    ) : (
      loader
    ),
    "/group/:groupId/:id": currentTicketTitle || loader,
    "/group/:groupId/:id/worktimes": "Czas pracy",
    "/group/:groupId/:id/edit": "Edycja",
    "/group/:groupId/:id/duplicate": "Kopia",
    "/group/:groupId/new-ticket": "Nowy wątek",
    "/group/:groupId/worktimes": "Czas pracy",
    "/kokpit": "Kokpit",
    "/archive": "Archiwum",
    "/users": "Użytkownicy",
    "/users/edit": currentEditedUser ? (
      <strong className="highlight">{currentEditedUser}</strong>
    ) : (
      loader
    ),
    "/users/edit/:id": "Edycja",
    "/groups": "Działy",
    "/worktimes": "Zestawienie",
    "/czat": "Czat",
    "/groups/:id": currentGroupTitle ? (
      <strong className="highlight">{currentGroupTitle}</strong>
    ) : (
      loader
    ),
    "/groups/:id/edit": "edycja",
    "/calendar": "Kalendarz",
    "/assigned": "Przydzielone do mnie",
    "/notifications": "Powiadomienia",
    "/profil": "Profil",
  };

  const { match } = props;
  const pathSnippets = match.path.split("/").filter((i: any) => i);
  const urlSnippets = match.url.split("/").filter((i: any) => i);

  const extraBreadcrumbItems = pathSnippets.map((_: any, index: number) => {
    const path = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    let url = `/${urlSnippets.slice(0, index + 1).join("/")}`;

    // check if we are on groups page
    const regaxp = /^\/groups\/([0-9]+)$/gm;
    const isRegaxMatched = regaxp.test(url);
    if (isRegaxMatched) {
      url = url.replace("groups", "group");
    }

    // check if we are on users edit page
    const userPageRegex = /^\/users\/edit$/;
    const isUserNameBreadCrumb = userPageRegex.test(url);

    if (breadcrumbNameMap[path]) {
      if (
        pathSnippets.length === index + 1 ||
        (isRegaxMatched && disabledLink) ||
        isUserNameBreadCrumb
      ) {
        return (
          <Breadcrumb.Item key={url}>
            <Text ellipsis style={{ maxWidth: 300 }}>
              {breadcrumbNameMap[path]}
            </Text>
          </Breadcrumb.Item>
        );
      }

      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>
            <Text ellipsis style={{ maxWidth: 300 }}>
              {breadcrumbNameMap[path]}
            </Text>
          </Link>
        </Breadcrumb.Item>
      );
    }
    return null;
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
        <span> Strona główna</span>
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <Row gutter={16} className="breadcrumbs">
      <Col span={24}>
        <Breadcrumb separator={<RightOutlined />}>{breadcrumbItems}</Breadcrumb>
      </Col>
    </Row>
  );
};

export default withRouter(Breadcrumbs);
